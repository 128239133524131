import { useGlobalContext } from "../context";

import "../styles/Books.scss";

import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import books from "../arrays/books";

import NavBar from "./Navbar";
import Footer from "./Footer";
import MenuModal from "./MenuModal";
import SingleBook from "./SingleBook";

function Books() {
  const { isDarkTheme } = useGlobalContext();

  return (
    <>
      <div
        className={`${isDarkTheme ? `navBarIntro-dark` : `navBarIntro-light`}`}
      >
        <NavBar />
      </div>
      <div className={`${isDarkTheme ? `content content-dark ` : `content `}`}>
        <div className="contentWrapper contentWrapper-books">
          <div
            className={`${
              isDarkTheme
                ? `content__books content__books-dark`
                : `content__books`
            }`}
          >
            <div
              className={`${
                isDarkTheme
                  ? `introduction__title introduction__title-fix introduction__title-dark`
                  : `introduction__title introduction__title-fix`
              }`}
            >
              Книги
            </div>
            <div
              className={`${
                isDarkTheme
                  ? `introduction__line introduction__line-dark`
                  : `introduction__line`
              }`}
            ></div>
            <div className="content__booksWrapper">
              {books.map((item) => (
                <SingleBook key={item.id} {...item}></SingleBook>
              ))}
            </div>
          </div>
          <button
            className={`${
              isDarkTheme
                ? `content__returnBtn content__returnBtn-dark`
                : `content__returnBtn`
            }`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <img
              src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
              alt="arrowTop"
            />
          </button>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default Books;
