import { useGlobalContext } from "../context";

import AudioPlayer from "../audio_player/AudioPlayer";
import SocialLinks from "./SocialLinks";

import "../styles/SingleNamaz.scss";

function SingleNamaz(props) {
  const { isDarkTheme } = useGlobalContext();

  let mainTitle;
  if (props.mainTitle) {
    mainTitle = (
      <>
        <div
          className={`${
            isDarkTheme
              ? `namaz__mainTitle namaz__mainTitle-dark`
              : `namaz__mainTitle`
          }`}
        >
          {props.mainTitle}
        </div>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
      </>
    );
  }

  let original;
  if (props.original) {
    original = (
      <>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
        <div className="azkar__original">{props.original}</div>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
      </>
    );
  }

  let ending;
  if (props.ending) {
    ending = (
      <>
        <div
          className={`${
            isDarkTheme
              ? `azkar__line azkar__line-ending azkar__line-dark`
              : `azkar__line azkar__line-ending`
          }`}
        ></div>
        <div
          className={`${
            isDarkTheme
              ? `namaz__mainTitle namaz__mainTitle-ending namaz__mainTitle-dark`
              : `namaz__mainTitle namaz__mainTitle-ending`
          }`}
        >
          {props.ending}
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={`${isDarkTheme ? `azkar azkar-dark` : `azkar`}`}
        id={props.linkId || null}
      >
        {mainTitle}
        <div className="ablution__img">
          <img
            className={props.img_className}
            src={`${isDarkTheme ? props.img_dark : props.img_light}`}
            alt="ablution_img"
          />
        </div>
        <div
          className={`${
            isDarkTheme
              ? `ablution__title ablution__title-dark`
              : `ablution__title`
          }`}
        >
          {props.title}
        </div>
        <div className="ablution__text">{props.text}</div>
        {original}
        <div className="ablution__translate">{props.translate}</div>
        <div className="azkar__player">
          <AudioPlayer audioSrc={props.audio}></AudioPlayer>
        </div>
        <SocialLinks></SocialLinks>
        {ending}
      </div>
    </>
  );
}

export default SingleNamaz;
