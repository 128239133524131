import social_vk_light from "../assets/social_vk_light.svg";
import social_vk_dark from "../assets/social_vk_dark.svg";
import social_whatsApp_light from "../assets/social_whatsApp_light.svg";
import social_whatsApp_dark from "../assets/social_whatsApp_dark.svg";
import social_facebook_light from "../assets/social_facebook_light.svg";
import social_facebook_dark from "../assets/social_facebook_dark.svg";
import social_telegram_light from "../assets/social_telegram_light.svg";
import social_telegram_dark from "../assets/social_telegram_dark.svg";
import social_twitter_light from "../assets/social_twitter_light.svg";
import social_twitter_dark from "../assets/social_twitter_dark.svg";
import social_inline_light from "../assets/social_inline_light.svg";
import social_inline_dark from "../assets/social_inline_dark.svg";

import { useGlobalContext } from "../context";

function SocialLinks() {
  const { isDarkTheme } = useGlobalContext();

  return (
    <>
      <div className="azkar__social">
        <a
          href="#"
          className={`${
            isDarkTheme ? "social__link social__link-dark" : "social__link"
          }`}
          onMouseOver={(e) =>
            (e.currentTarget.querySelector("img").src = social_vk_dark)
          }
          onMouseOut={(e) => {
            if (!isDarkTheme)
              e.currentTarget.querySelector("img").src = social_vk_light;
          }}
        >
          <img
            src={`${isDarkTheme ? social_vk_dark : social_vk_light}`}
            alt="vk_icon"
          />
        </a>
        <a
          href="#"
          className={`${
            isDarkTheme ? "social__link social__link-dark" : "social__link"
          }`}
          onMouseOver={(e) =>
            (e.currentTarget.querySelector("img").src = social_whatsApp_dark)
          }
          onMouseOut={(e) => {
            if (!isDarkTheme)
              e.currentTarget.querySelector("img").src = social_whatsApp_light;
          }}
        >
          <img
            src={`${
              isDarkTheme ? social_whatsApp_dark : social_whatsApp_light
            }`}
            alt="whatsApp_icon"
          />
        </a>
        <a
          href="#"
          className={`${
            isDarkTheme ? "social__link social__link-dark" : "social__link"
          }`}
          onMouseOver={(e) =>
            (e.currentTarget.querySelector("img").src = social_facebook_dark)
          }
          onMouseOut={(e) => {
            if (!isDarkTheme)
              e.currentTarget.querySelector("img").src = social_facebook_light;
          }}
        >
          <img
            src={`${
              isDarkTheme ? social_facebook_dark : social_facebook_light
            }`}
            alt="facebook_icon"
          />
        </a>
        <a
          href="#"
          className={`${
            isDarkTheme ? "social__link social__link-dark" : "social__link"
          }`}
          onMouseOver={(e) =>
            (e.currentTarget.querySelector("img").src = social_telegram_dark)
          }
          onMouseOut={(e) => {
            if (!isDarkTheme)
              e.currentTarget.querySelector("img").src = social_telegram_light;
          }}
        >
          <img
            src={`${
              isDarkTheme ? social_telegram_dark : social_telegram_light
            }`}
            alt="telegram_icon"
          />
        </a>
        <a
          href="#"
          className={`${
            isDarkTheme ? "social__link social__link-dark" : "social__link"
          }`}
          onMouseOver={(e) =>
            (e.currentTarget.querySelector("img").src = social_twitter_dark)
          }
          onMouseOut={(e) => {
            if (!isDarkTheme)
              e.currentTarget.querySelector("img").src = social_twitter_light;
          }}
        >
          <img
            src={`${isDarkTheme ? social_twitter_dark : social_twitter_light}`}
            alt="twitter_icon"
          />
        </a>
        <a
          href="#"
          className={`${
            isDarkTheme
              ? "social__link social__link-inline social__link-dark"
              : "social__link social__link-inline"
          }`}
          onMouseOver={(e) =>
            (e.currentTarget.querySelector("img").src = social_inline_dark)
          }
          onMouseOut={(e) => {
            if (!isDarkTheme)
              e.currentTarget.querySelector("img").src = social_inline_light;
          }}
        >
          <img
            src={`${isDarkTheme ? social_inline_dark : social_inline_light}`}
            alt="twitter_icon"
          />
        </a>
      </div>
    </>
  );
}

export default SocialLinks;
