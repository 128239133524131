import img_1_dark from "../assets/ablutions/ablution_1_dark.svg";
import img_1_light from "../assets/ablutions/ablution_1_light.svg";
import img_2_dark from "../assets/ablutions/ablution_2_dark.svg";
import img_2_light from "../assets/ablutions/ablution_2_light.svg";
import img_3_dark from "../assets/ablutions/ablution_3_dark.svg";
import img_3_light from "../assets/ablutions/ablution_3_light.svg";
import img_4_dark from "../assets/ablutions/ablution_4_dark.svg";
import img_4_light from "../assets/ablutions/ablution_4_light.svg";
import img_5_dark from "../assets/ablutions/ablution_5_dark.svg";
import img_5_light from "../assets/ablutions/ablution_5_light.svg";
import img_6_dark from "../assets/ablutions/ablution_6_dark.svg";
import img_6_light from "../assets/ablutions/ablution_6_light.svg";
import img_7_dark from "../assets/ablutions/ablution_7_dark.svg";
import img_7_light from "../assets/ablutions/ablution_7_light.svg";
import img_8_dark from "../assets/ablutions/ablution_8_dark.svg";
import img_8_light from "../assets/ablutions/ablution_8_light.svg";
import img_9_dark from "../assets/ablutions/ablution_9_dark.svg";
import img_9_light from "../assets/ablutions/ablution_9_light.svg";

const ablutions = [
  {
    id: "a1",
    img_dark: img_1_dark,
    img_light: img_1_light,
    img_className: "img1",
    title: "Шаг №1",
    text: "Сделав в своем сердце намерение совершить малое омовение, говорит:",
    original: "ﺑِﺴْﻢِ ٱﻟﻠّٰﻪِ",
    translate: (
      <>
        Би-сми Ллях <br />С именем Аллаха
      </>
    ),
    audioPlayer: true,
  },
  {
    id: "a2",
    img_dark: img_2_dark,
    img_light: img_2_light,
    img_className: "img2",
    title: "Шаг №2",
    text: "Трижды тщательно моет руки, включая запястья, и промывает при этом область между пальцами",
  },
  {
    id: "a3",
    img_dark: img_3_dark,
    img_light: img_3_light,
    img_className: "img3",
    title: "Шаг №3",
    text: "Трижды полоскает рот. Набрав воду в правую ладонь, он набирает воду в рот, прополаскивает ротовую полость и выплевывает воду.",
  },
  {
    id: "a4",
    img_dark: img_4_dark,
    img_light: img_4_light,
    img_className: "img4",
    title: "Шаг №4",
    text: "Трижды прочищает нос. Вбирает воду из правой ладони как можно глубже, а затем высмаркивает ее с помощью левой руки. (Полоскание рта и прочищение носа можно делать одновременно)",
  },
  {
    id: "a5",
    img_dark: img_5_dark,
    img_light: img_5_light,
    img_className: "img5",
    title: "Шаг №5",
    text: "Моет лицо - всю область от границы роста волос надо лбом до подбородка и от уха до уха. При этом нужно следить за тем, чтобы вода попала на всю указанную область. Это можно сделать как однократно, так и троекратно. (Если у мусульманина есть борода, ему следует отдельно набрать пригоршню воды, выплеснуть ее на подбородок и прочесать влажными пальцами бороду до корней)",
  },
  {
    id: "a6",
    img_dark: img_6_dark,
    img_light: img_6_light,
    img_className: "img6",
    title: "Шаг №6",
    text: "Трижды моет руки до локтей включительно, начиная с правой руки. Руки необходимо мыть от кончиков пальцев до локтей.",
  },
  {
    id: "a7",
    img_dark: img_7_dark,
    img_light: img_7_light,
    img_className: "img7",
    title: "Шаг №7",
    text: "Один раз протирает голову мокрыми руками от границы роста волос надо лбом к затылку и обратно - одним движением.",
  },
  {
    id: "a8",
    img_dark: img_8_dark,
    img_light: img_8_light,
    img_className: "img8",
    title: "Шаг №8",
    text: "Один раз протирает влажными указательными пальцами внутреннюю поверхность ушей, а большими пальцами - заднюю поверхность ушей; оба уха протираются одновременно.",
  },
  {
    id: "a9",
    img_dark: img_9_dark,
    img_light: img_9_light,
    img_className: "img9",
    title: "Шаг №9",
    text: "Трижды моет ноги до щиколоток включительно, начиная с правой ноги. Нужно также промывать промежутки между пальцами.",
  },
];

export default ablutions;
