import namaz_a1_light from "../assets/namaz/namaz_a1_light.svg";
import namaz_a1_dark from "../assets/namaz/namaz_a1_dark.svg";
import namaz_a2_light from "../assets/namaz/namaz_a2_light.svg";
import namaz_a2_dark from "../assets/namaz/namaz_a2_dark.svg";
import namaz_a3_light from "../assets/namaz/namaz_a3_light.svg";
import namaz_a3_dark from "../assets/namaz/namaz_a3_dark.svg";
import namaz_a4_light from "../assets/namaz/namaz_a4_light.svg";
import namaz_a4_dark from "../assets/namaz/namaz_a4_dark.svg";
import namaz_a5_light from "../assets/namaz/namaz_a5_light.svg";
import namaz_a5_dark from "../assets/namaz/namaz_a5_dark.svg";
import namaz_a6_light from "../assets/namaz/namaz_a6_light.svg";
import namaz_a6_dark from "../assets/namaz/namaz_a6_dark.svg";
import namaz_a7_light from "../assets/namaz/namaz_a7_light.svg";
import namaz_a7_dark from "../assets/namaz/namaz_a7_dark.svg";
import namaz_a8_light from "../assets/namaz/namaz_a8_light.svg";
import namaz_a8_dark from "../assets/namaz/namaz_a8_dark.svg";
import namaz_a9_light from "../assets/namaz/namaz_a9_light.svg";
import namaz_a9_dark from "../assets/namaz/namaz_a9_dark.svg";
import namaz_a10_light from "../assets/namaz/namaz_a10_light.svg";
import namaz_a10_dark from "../assets/namaz/namaz_a10_dark.svg";
import namaz_a11_light from "../assets/namaz/namaz_a11_light.svg";
import namaz_a11_dark from "../assets/namaz/namaz_a11_dark.svg";
import namaz_a12_light from "../assets/namaz/namaz_a12_light.svg";
import namaz_a12_dark from "../assets/namaz/namaz_a12_dark.svg";
import namaz_a13_light from "../assets/namaz/namaz_a13_light.svg";
import namaz_a13_dark from "../assets/namaz/namaz_a13_dark.svg";
import namaz_a14_light from "../assets/namaz/namaz_a14_light.svg";
import namaz_a14_dark from "../assets/namaz/namaz_a14_dark.svg";

const namaz1 = [
  {
    id: "a1",
    img_dark: namaz_a1_dark,
    img_light: namaz_a1_light,
    img_className: "img_a1",
    linkId: "first__rakat",
    mainTitle: <>Совершение первого ракаата молитвы</>,
    title: "Шаг №1",
    text: "Стоя, он устремляет взор на то место, которого коснется его голова во время земного поклона, поднимает руки до уровня ушей или плеч и говорит:",
    original: "اللهُ أكبَـرُ",
    translate: (
      <>
        Аллаху акбару <br />
        Аллах велик
      </>
    ),
  },
  {
    id: "a2",
    img_dark: namaz_a2_dark,
    img_light: namaz_a2_light,
    img_className: "img_a2",
    title: "Шаг №2",
    text: "Он складывает руки на груди, возлагая правую руку поверх левой, и говорит:",
    original: "ﺃﻋُﻮﺫُ ﺑِﺎﻟﻠﻪِ ﻣِﻦَ ﺍﻟﺸَّﻴْﻄَﺎﻥِ ﺍﻟﺮَّﺟِﻴﻢ",
    translate: (
      <>
        А'узу би-Лляхи мин аш-шайтани р-раджим <br />
        Ищу у Аллаха защиты от проклятого шайтана
      </>
    ),
  },
  {
    id: "a3",
    img_dark: namaz_a3_dark,
    img_light: namaz_a3_light,
    img_className: "img_a3",
    title: "Шаг №3",
    text: <>Читает суру "Аль-Фатиха"</>,
    translate: (
      <>
        <div style={{ marginTop: "18px" }}>
          <i>
            Би-сми Лляхи р-Рахмани р-РахимАль-хамду ли-Лляхи рабби ль-‘алямин
            Ар-Рахмани р-Рахим Малики йауми д-дин Иййака на‘буду ва иййака
            наста‘ин Ихди-на с-сырата ль-мустакым Сырат аллязина ан‘амта
            ‘алей-хим гайри ль-магдуби ‘алей-хим ва ля д-даллин
          </i>
        </div>
      </>
    ),
  },
  {
    id: "a4",
    img_dark: namaz_a4_dark,
    img_light: namaz_a4_light,
    img_className: "img_a4",
    title: "Шаг №4",
    text: "После этого он говорит:",
    original: "ﺁﻣِﻴﻦْ",
    translate: (
      <>
        Амин! <br />О Аллах, ответь на наши мольбы!
      </>
    ),
  },
  {
    id: "a5",
    img_dark: namaz_a5_dark,
    img_light: namaz_a5_light,
    img_className: "img_a5",
    title: "Шаг №5",
    text: "Стоя, он поднимает руки до уровня ушей или плеч, повернув их ладонями от себя, после чего говорит:",
    original: "اللهُ أكبَـرُ",
    translate: (
      <>
        Аллаху акбару <br />
        Аллах велик
      </>
    ),
  },
  {
    id: "a6",
    img_dark: namaz_a6_dark,
    img_light: namaz_a6_light,
    img_className: "img_a6",
    title: "Шаг №6",
    text: "Он совершает поясной поклон и, застыв в нем, трижды произносит:",
    original: "ِﺳُﺒْﺤَﺎﻥَ ﺭَﺑِّﻲَ ﺍﻟﻌَﻈﻴﻢ",
    translate: (
      <>
        Субхана Раббийа ль-‘Азыми <br />
        Пречист Господь мой Великий.
      </>
    ),
  },
  {
    id: "a7",
    img_dark: namaz_a7_dark,
    img_light: namaz_a7_light,
    img_className: "img_a7",
    title: "Шаг №7",
    text: "Он поднимает руки до уровня плеч или ушей и выпрямляется со словами:",
    original: "ﺳَﻤِﻊَ ﺍﻟﻠﻪُ ﻟِﻤَﻦْ ﺣَﻤِﺪَﻩُ",
    translate: (
      <>Сами‘а Ллаху ли-ман хамида-ху Слышит Аллах того, кто восхваляет Его.</>
    ),
  },
  {
    id: "a8",
    img_dark: namaz_a8_dark,
    img_light: namaz_a8_light,
    img_className: "img_a8",
    title: "Шаг №8",
    text: "Полностью выпрямившись, он говорит:",
    original: "ﺭَﺑَّﻨَﺎ وَ ﻟَﻚَ ﺍﻟْﺤَﻤْﺪُ",
    translate: (
      <>
        Рабба-на ва ля-ка ль-хамду <br />
        Господь наш, Тебе хвала!
      </>
    ),
  },
  {
    id: "a9",
    img_dark: namaz_a9_dark,
    img_light: namaz_a9_light,
    img_className: "img_a9",
    title: "Шаг №9",
    text: "Он совершает земной поклон, сказав:",
    original: "اللهُ أكبَـرُ",
    translate: (
      <>
        Аллаху акбару <br />
        Аллах велик
      </>
    ),
  },
  {
    id: "a10",
    img_dark: namaz_a10_dark,
    img_light: namaz_a10_light,
    img_className: "img_a10",
    title: "Шаг №10",
    text: "При этом нос и лоб касаются пола, ладони с прижатыми друг к другу пальцами лежат на полу, находясь на одной линии с плечами или с ушами. Предплечья приподняты так, что локти не касаются пола. Также в пол упираются колени и пальцы поставленных вертикально ступней. В таком положении он трижды произносит:",
    original: "سُبْحَانَ رَبِّيَ الأأعْلَي",
    translate: (
      <>
        Субхана Раббийа ль-А‘ля <br />
        Пречист Господь мой Высочайший.
      </>
    ),
  },
  {
    id: "a11",
    img_dark: namaz_a11_dark,
    img_light: namaz_a11_light,
    img_className: "img_a11",
    title: "Шаг №11",
    text: "Он поднимает голову со словами:",
    original: "اللهُ أكبَـرُ",
    translate: (
      <>
        Аллаху акбару <br />
        Аллах велик.
      </>
    ),
  },
  {
    id: "a12",
    img_dark: namaz_a12_dark,
    img_light: namaz_a12_light,
    img_className: "img_a12",
    title: "Шаг №12",
    text: "Затем садится так: простирает левую ступню, садится на неё, а правую ставит вертикально, направляя пальцы в сторону киблы. Руки покоятся на коленях. Сев так, он трижды произносит:",
    original: "ﺭبِّ ﺍﻏْﻔِﺮْ ﻟِﻲ",
    translate: (
      <>
        Рабби гфир-ли <br />
        Господь мой, прости меня.
      </>
    ),
  },
  {
    id: "a13",
    img_dark: namaz_a13_dark,
    img_light: namaz_a13_light,
    img_className: "img_a13",
    title: "Шаг №13",
    text: "Он снова совершает земной поклон, принимая положение, описанное в шаге 10, и произнося при этом:",
    original: "اللهُ أكبَـرُ",
    translate: (
      <>
        Аллаху акбару <br />
        Аллах велик.
      </>
    ),
  },
  {
    id: "a14",
    img_dark: namaz_a14_dark,
    img_light: namaz_a14_light,
    img_className: "img_a14",
    title: "Шаг №14",
    text: "В таком положении он трижды произносит:",
    original: "سُبْحَانَ رَبِّيَ الأأعْلَي",
    translate: (
      <>
        Субхана Раббийа ль-А‘ля <br />
        Пречист Господь мой Высочайший.
      </>
    ),
  },
];

export default namaz1;
