import { useGlobalContext } from "../context";

import namaz1 from "../arrays/namaz1";
import namaz2 from "../arrays/namaz2";
import namaz3 from "../arrays/namaz3";

import scrollDark from "../assets/scrollDark.svg";
import scrollMobDark from "../assets/scrollMobDark.svg";
import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import NavBarDarkOnly from "./NavBarDarkOnly";
import SingleNamaz from "./SingleNamaz";
import Footer from "./Footer";
import MenuModal from "./MenuModal";

import "../styles/Namaz.scss";
import { Link } from "react-router-dom";

function Namaz() {
  const { isDarkTheme, setIntroductionAblutionNamazBack } = useGlobalContext();

  const handleClickScroll = (prop) => {
    const element = document.getElementById(prop);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", alignToTop: true });
    }
  };

  return (
    <>
      <header className="header header-namaz">
        <div className="navBar-namaz">
          <NavBarDarkOnly />
        </div>
        <div className="headerWrapper headerWrapper-ablution">
          <span className="header__title header__title-dark">Намаз</span>
          <div className="header__vertLine header__vertLine-dark header__vertLine-ablution"></div>
          <img
            className="header__scroll-desc"
            src={scrollDark}
            alt="scroll-desc"
          />
          <img
            className="header__scroll-mobile"
            src={scrollMobDark}
            alt="scroll-mobile"
          />
        </div>
      </header>
      <div className={`${isDarkTheme ? `content content-dark` : `content`}`}>
        <div className="contentWrapper">
          <Link
            to={`/introduction_ablution&namaz`}
            onClick={setIntroductionAblutionNamazBack("namaz")}
            className={`${
              isDarkTheme
                ? `content__introductionBtn content__introductionBtn-dark`
                : `content__introductionBtn`
            }`}
          >
            Открыть вступление
          </Link>
          <div className="content__ablution">
            {namaz1.map((item) => (
              <SingleNamaz key={item.id} {...item}></SingleNamaz>
            ))}
            {namaz2.map((item) => (
              <SingleNamaz key={item.id} {...item}></SingleNamaz>
            ))}
            <div className={`${isDarkTheme ? `azkar azkar-dark` : `azkar`}`}>
              <div
                className={`${
                  isDarkTheme
                    ? `namaz__mainTitle namaz__mainTitle-dark`
                    : `namaz__mainTitle`
                }`}
              >
                Что делать дальше?
              </div>
              <div
                className={`${
                  isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
                }`}
              ></div>
              <div className="ablution__text">
                Итак, молящийся совершил два раката молитвы. Его дальнейшие
                действия зависят от того, какую молитву он совершает и из
                скольких ракатов она состоит.
              </div>
              <div
                className={`${
                  isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
                }`}
              ></div>
              <div className="namaz__stepsWrapper">
                <div className="namaz__singleStep">
                  <div
                    className={`${
                      isDarkTheme
                        ? `ablution__title ablution__title-dark`
                        : `ablution__title`
                    }`}
                  >
                    Фаджр
                  </div>
                  <div className="namaz__btnWrapper">
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      onClick={() => handleClickScroll("first__rakat")}
                    >
                      Первый ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      onClick={() => handleClickScroll("second__rakat")}
                    >
                      Второй ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-big namaz__btnBlue namaz__btnBlue-dark`
                          : `namaz__btn namaz__btn-big namaz__btnBlue`
                      }`}
                      onClick={() => handleClickScroll("grace__end")}
                    >
                      Завершение молитвы
                    </button>
                  </div>
                </div>
                <div className="namaz__singleStep">
                  <div
                    className={`${
                      isDarkTheme
                        ? `ablution__title ablution__title-dark`
                        : `ablution__title`
                    }`}
                  >
                    Зухр, ‘Аср, ‘Иша’
                  </div>
                  <div className="namaz__btnWrapper">
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      onClick={() => handleClickScroll("first__rakat")}
                    >
                      Первый ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      onClick={() => handleClickScroll("second__rakat")}
                    >
                      Второй ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnBlue namaz__btnBlue-dark`
                          : `namaz__btn namaz__btn-small namaz__btnBlue`
                      }`}
                      // onClick={() => handleClickScroll("third__rakat")}
                    >
                      Третий ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-big namaz__btnBlue namaz__btnBlue-dark`
                          : `namaz__btn namaz__btn-big namaz__btnBlue`
                      }`}
                      // onClick={() => handleClickScroll("fourths__rakat")}
                    >
                      Четвертый ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-big namaz__btnBlue namaz__btnBlue-dark`
                          : `namaz__btn namaz__btn-big namaz__btnBlue`
                      }`}
                      onClick={() => handleClickScroll("grace__end")}
                    >
                      Завершение молитвы
                    </button>
                  </div>
                </div>
                <div className="namaz__singleStep">
                  <div
                    className={`${
                      isDarkTheme
                        ? `ablution__title ablution__title-dark`
                        : `ablution__title`
                    }`}
                  >
                    Магриб
                  </div>
                  <div className="namaz__btnWrapper">
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      onClick={() => handleClickScroll("first__rakat")}
                    >
                      Первый ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      onClick={() => handleClickScroll("second__rakat")}
                    >
                      Второй ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-small namaz__btnWhite namaz__btnWhite-dark`
                          : `namaz__btn namaz__btn-small namaz__btnWhite`
                      }`}
                      // onClick={() => handleClickScroll("third__rakat")}
                    >
                      Третий ракаат
                    </button>
                    <button
                      className={`${
                        isDarkTheme
                          ? `namaz__btn namaz__btn-dark namaz__btn-big namaz__btnBlue namaz__btnBlue-dark`
                          : `namaz__btn namaz__btn-big namaz__btnBlue`
                      }`}
                      onClick={() => handleClickScroll("grace__end")}
                    >
                      Завершение молитвы
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {namaz3.map((item) => (
              <SingleNamaz key={item.id} {...item}></SingleNamaz>
            ))}
          </div>
          <button
            className={`${
              isDarkTheme
                ? `content__returnBtn content__returnBtn-dark`
                : `content__returnBtn`
            }`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <img
              src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
              alt="arrowTop"
            />
          </button>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default Namaz;
