import { useGlobalContext } from "../context";
import btn__download from "../assets/btn__download.svg";
import btn__download_dark from "../assets/btn__download_dark.svg";

import "../styles/SingleBook.scss";

function SingleBook(props) {
  const { isDarkTheme } = useGlobalContext();

  return (
    <>
      <div className="book">
        <div className="bookImgWrap">
          <img
            className={`${
              isDarkTheme ? `book__img book__img-dark` : `book__img`
            }`}
            src={props.img}
            alt="book__btn"
          />
        </div>
        <div className="bookInfoWrap">
          <div className="bookTextWrap">
            <div
              className={`${
                isDarkTheme ? `book__title book__title-dark` : `book__title`
              }`}
            >
              {props.title}
            </div>
            <div
              className={`${
                isDarkTheme ? `book__author book__author-dark` : `book__author`
              }`}
            >
              {props.authors}
            </div>
          </div>
          <button
            className={`${
              isDarkTheme ? `book__btn book__btn-dark` : `book__btn`
            }`}
          >
            <span
              className={`${
                isDarkTheme
                  ? `book__btn__text book__btn__text-dark`
                  : `book__btn__text`
              }`}
            >
              Скачать
            </span>
            <img
              src={`${isDarkTheme ? btn__download_dark : btn__download}`}
              alt="download__btn"
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default SingleBook;
