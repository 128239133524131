import { useGlobalContext } from "../context";

import morning_azkaras from "../arrays/morning_azkaras";
import morning_footnotes from "../arrays/morning_footnotes";

import scrollLight from "../assets/scrollLight.svg";
import scrollDark from "../assets/scrollDark.svg";
import scrollMobLight from "../assets/scrollMobLight.svg";
import scrollMobDark from "../assets/scrollMobDark.svg";
import arrowRight from "../assets/arrow_right.svg";
import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import NavBar from "./Navbar";
import Footer from "./Footer";
import SingleAzkar from "./SingleAzkar";
// import SingleFootnote from "./SingleFootnote";
import FootnotesModalMorning from "./FootnotesModalMorning";
import MenuModal from "./MenuModal";

import "../styles/Azkaras.scss";
import { Link } from "react-router-dom";

function AzkarasMorning() {
  const {
    isDarkTheme,
    setModalMorning,
    setModalMorningOpen,
    setIntroductionAzkarasBack,
  } = useGlobalContext();

  function handleClick(value) {
    return () => {
      setModalMorning(value);
      setModalMorningOpen(true);
    };
  }

  return (
    <>
      <header
        className={`${
          isDarkTheme ? `header morning morning-dark` : `header morning`
        }`}
      >
        <NavBar />
        <div className="headerWrapper">
          <span
            className={`${
              isDarkTheme ? `header__title header__title-dark` : `header__title`
            }`}
          >
            Утренние азкары
          </span>
          <div
            className={`${
              isDarkTheme
                ? `header__vertLine header__vertLine-dark`
                : `header__vertLine`
            }`}
          ></div>
          <div
            className={`${
              isDarkTheme ? `header__desc header__desc-dark` : `header__desc`
            }`}
          >
            Их время начинается сразу после утренней молитвы (аль-фаджр) и
            продолжается до восхода солнца
          </div>
          <Link to={`/evening_azkaras`} className="header__btn">
            Вечерние азкары
            <img src={arrowRight} alt="arrowRight" />
          </Link>
          <img
            className="header__scroll-desc"
            src={`${isDarkTheme ? scrollDark : scrollLight}`}
            alt="scroll-desc"
          />
          <img
            className="header__scroll-mobile"
            src={`${isDarkTheme ? scrollMobDark : scrollMobLight}`}
            alt="scroll-mobile"
          />
        </div>
      </header>
      <div className={`${isDarkTheme ? `content content-dark` : `content`}`}>
        <div className="contentWrapper">
          <Link
            to={`/introduction_azkaras`}
            onClick={setIntroductionAzkarasBack("")}
            className={`${
              isDarkTheme
                ? `content__introductionBtn content__introductionBtn-dark`
                : `content__introductionBtn`
            }`}
          >
            Открыть вступление
          </Link>
          <div className="content__azkaras">
            {morning_azkaras.map((item) => (
              <SingleAzkar
                key={item.id}
                {...item}
                handleClick={handleClick}
              ></SingleAzkar>
            ))}
          </div>
          {/* <div
            className={`${
              isDarkTheme
                ? `content__footnotes content__footnotes-dark`
                : `content__footnotes`
            }`}
          >
            {morning_footnotes.map((item) => (
              <SingleFootnote key={item.id} {...item}></SingleFootnote>
            ))}
          </div> */}
          <button
            className={`${
              isDarkTheme
                ? `content__returnBtn content__returnBtn-dark`
                : `content__returnBtn`
            }`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <img
              src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
              alt="arrowTop"
            />
          </button>
        </div>
      </div>
      <Footer></Footer>
      <FootnotesModalMorning></FootnotesModalMorning>
      <MenuModal></MenuModal>
    </>
  );
}

export default AzkarasMorning;
