import namaz_b1_light from "../assets/namaz/namaz_b1_light.svg";
import namaz_b1_dark from "../assets/namaz/namaz_b1_dark.svg";
import namaz_b2_light from "../assets/namaz/namaz_b2_light.svg";
import namaz_b2_dark from "../assets/namaz/namaz_b2_dark.svg";
import namaz_b3_light from "../assets/namaz/namaz_b3_light.svg";
import namaz_b3_dark from "../assets/namaz/namaz_b3_dark.svg";
import namaz_b4_light from "../assets/namaz/namaz_b4_light.svg";
import namaz_b4_dark from "../assets/namaz/namaz_b4_dark.svg";

const namaz2 = [
  {
    id: "b1",
    img_dark: namaz_b1_dark,
    img_light: namaz_b1_light,
    img_className: "img_b1",
    linkId: "second__rakat",
    mainTitle: <>Совершение второго ракаата молитвы</>,
    title: "Шаг №1",
    text: "Молящийся поднимается из земного поклона, вставая со словами:",
    original: "اللهُ أكبَـرُ",
    translate: (
      <>
        Аллаху акбару <br />
        Аллах велик
      </>
    ),
  },
  {
    id: "b2",
    img_dark: namaz_b2_dark,
    img_light: namaz_b2_light,
    img_className: "img_b2",
    title: "Шаг №2",
    text: (
      <>
        Он повторяет <u>шаги 3–14</u> из описания первого раката молитвы.
      </>
    ),
  },
  {
    id: "b3",
    img_dark: namaz_b3_dark,
    img_light: namaz_b3_light,
    img_className: "img_b3",
    title: "Шаг №3",
    text: <>Читает суру "Аль-Фатиха"</>,
    translate: (
      <>
        <div className="namaz__translateFix">
          <i>
            Би-сми Лляхи р-Рахмани р-РахимАль-хамду ли-Лляхи рабби ль-‘алямин
            Ар-Рахмани р-Рахим Малики йауми д-дин Иййака на‘буду ва иййака
            наста‘ин Ихди-на с-сырата ль-мустакым Сырат аллязина ан‘амта
            ‘алей-хим гайри ль-магдуби ‘алей-хим ва ля д-даллин
          </i>
        </div>
      </>
    ),
  },
  {
    id: "b4",
    img_dark: namaz_b4_dark,
    img_light: namaz_b4_light,
    img_className: "img_b4",
    title: "Шаг №4",
    text: "После этого он говорит:",
    original: "ﺁﻣِﻴﻦْ",
    translate: (
      <>
        Амин! <br />О Аллах, ответь на наши мольбы!
      </>
    ),
  },
];

export default namaz2;
