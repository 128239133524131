import "../styles/App.scss";

import { Routes, Route } from "react-router-dom";

import AzkarasMorning from "./AzkarasMorning";
import AzkarasEvening from "./AzkarasEvening";
import IntroductionAzkaras from "./IntroductionAzkaras";
import IntroductionAblutionNamaz from "./IntroductionAblutionNamaz";
import Ablution from "./Ablution";
import Namaz from "./Namaz";
import Books from "./Books";
import Menu from "./Menu";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/morning_azkaras" element={<AzkarasMorning />} />
        <Route path="/evening_azkaras" element={<AzkarasEvening />} />
        <Route path="/introduction_azkaras" element={<IntroductionAzkaras />} />
        <Route path="/ablution" element={<Ablution />} />
        <Route path="/namaz" element={<Namaz />} />
        <Route path="/books" element={<Books />} />
        <Route
          path="/introduction_ablution&namaz"
          element={<IntroductionAblutionNamaz></IntroductionAblutionNamaz>}
        />
      </Routes>
    </div>
  );
}

export default App;
