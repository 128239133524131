const handleClick = () => {
  console.log("test");
};

const morning_azkaras = [
  {
    id: 1,
    Title: () => (
      <>
        Вечерний азкар №1{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ١– أمسينا وأمسى الملكُ للَّهِ والحمدُ للَّهِ ، لا إلَه إلَّا اللَّهُ
        وحدَه لا شريكَ لهُ لَهُ المُلْكُ ولَهُ الحَمْدُ وَهُوَ عَلَى كُلِّ
        شَيْءٍ قَدِيرٌ، ربِّ أسألُكَ خيرَ هذه الليلة وخيرَ ما بعدَها وأعوذُ بِكَ
        من شرِّ هذه الليلة وشرِّ ما بعدَها ربِّ أَعُوذُ بِك منَ الكَسَلِ وسُوءِ
        الكِبَرِ وأَعُوذُ بك مِنْ عذابِ فِي النَّارِ و عذابِ فِي القَبْرِ
      </>
    ),
    translate: (
      <>
        Мы встретили вечер, и встретило вечер всё, что принадлежит Аллаху. Вся
        хвала Аллаху. Нет божества, достойного поклонения, кроме Аллаха,
        Единственного, у Которого нет сотоварища. Ему принадлежит вся власть, и
        ему принадлежит вся хвала и Он над каждой вещью мощен. О Господь мой, я
        прошу Тебя о благе, которое будет в эту ночь, и о благе, которое
        последует за ней, и я прибегаю к Тебе за защитой от зла, которое будет в
        эту ночь, и зла, которое за ней последует. Господь мой, я прибегаю к
        защите Твоей от лени и злополучной старости. О Господь мой, я прошу у
        Тебя защиты от мучений в Аду и от мучений в могиле.
      </>
    ),
    transcription: (
      <>
        Амсайна ва амса-ль-мульку ли-Лляхи ва-ль-хамду ли-Лляхи, ля иляха илля
        Ллаху вахда-ху ля шарикя ля-ху, ля-ху-ль-мульку ва ля-ху-ль-хамду ва хуа
        ааля кулли шайин кадирун. Рабби ас'алюкя хайра ма фи хазихи-ль-лейляти
        ва хайра ма ба'адаха ва а'узу бикя мин шарри ма фи хазихи-ль-лейляти ва
        шарри ма ба'адаха Рабби, а'узу би-кя мин аль-кясали ва суи-ль-кибари,
        Рабби, а'узу би-кя мин 'азабин фи-н-нари ва 'азабин фи-ль-кабри!
      </>
    ),
  },
  {
    id: 2,
    Title: () => (
      <>
        Вечерний азкар №2{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ٢ - اللهمَّ بِكَ أَمْسَيْنا، و بِكَ أَصْبَحْنا، وبِكَ نَحْيا و بِكَ
        نَمُوتُ، و إليكَ المَصِير
      </>
    ),
    translate: (
      <>
        О Аллах! Поминая Тебя, мы встретили вечер и, поминая Тебя, мы встретим
        утро, поминая Тебя, мы живем, и, поминая Тебя, мы умрём, и Тебе
        принадлежат наши судьбы.
      </>
    ),
    transcription: (
      <>
        Аллахума, би-ка амсайна, ва би-ка нахйа, ва би-ка намуту ва иляй-ка- ль-
        масыру.
      </>
    ),
  },
  {
    id: 3,
    Title: () => (
      <>
        Вечерний азкар №3{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ٣ - اللَّهمَّ أنتَ ربِّي لا إلَهَ إلَّا أنتَ، خَلقتَني وأَنا عبدُكَ،
        وأَنا على عَهْدِكَ ووعدِكَ ما استطعتُ، أعوذُ بِكَ من شرِّ ما صنعتُ،
        أبوءُ لَكَ بنعمتِكَ عليَّ، وأبوءُ لَكَ بذنبي فاغفِر لي، فإنَّهُ لا يغفرُ
        الذُّنوبَ إلَّا أنتَ
      </>
    ),
    translate: (
      <>
        О Аллах, Ты Господь мой, нет божества, достойного поклонения, кроме
        Тебя. Ты создал меня, и я - раб Твой, и я по мере моих сил придерживаюсь
        завета, данного Тебе и верю в обещание Твое. Я прибегаю к Твоей защите
        от зла того, что я сделал. Я признаю милость Твою, оказанную мне, и
        признаю грех свой, так прости же меня, ибо поистине, никто не прощает
        грехи, кроме Тебя!
      </>
    ),
    transcription: (
      <>
        Аллахумма, Анта Рабби, ля иляха илля Анта, халякта-ни ва ана 'абду-кя,
        ва ана "аля 'ахди-кя ва ва'ди-кя ма-стата'ту. А'узу би-кя мин шарри ма
        сана'ту, абу'у ля-кя би-ни'мати-кя 'аляййя, ва абу'у би-занби, фа-гфир
        ли, фа-инна-ху ля йагфи-ру-з-зунуба илля Анта!
      </>
    ),
  },
  {
    id: 4,
    Title: () => (
      <>
        Вечерний азкар №4{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ٤ - اللَّهمَّ فاطرَ السَّمَوَاتِ والأرضِ ، عالمَ الغيبِ والشَّهادةِ ،
        ربَّ كلِّ شيءٍ ومَلِيكَهُ، أشهَدُ أن لا إلَهَ إلَّا أنتَ ، أعوذُ بِكَ
        منَ شر نفسي وشر الشَّيطانِ وشِركِهِ ، وأَنْ أقترِفَ علَى نَفسي سُوءً
        أَوْ أجُرَّهُ إلى مُسْلِمٍ
      </>
    ),
    translate: (
      <>
        О Аллах, Создатель небес и земли, Знающий сокровенное и явное, Господь
        всякой вещи и Властелин её. Я свидетельствую, что нет божества,
        достойного поклонения, кроме Тебя, и прошу у Тебя защиты от зла моей
        души, и от зла шайтана и его многобожия, и от того, чтобы причинить зло
        самому себе или навлечь его на кого-либо из мусульман.
      </>
    ),
    transcription: (
      <>
        Аллахумма, 'Алима-ль-гайби ва-ш-шахадати, Фатира-с-самавати ва-ль-арди,
        Рабба кулли шайин ва Малика-ху, ашхаду алля иляха илля Анта, а'узу би-кя
        мин шарри нафси, ва мин шарри-ш-шайтани ва ширки-хи ва ан актарифа 'аля
        нафси су'ан ау аджурра-ху иля мусли-мин.
      </>
    ),
  },
  {
    id: 5,
    Title: () => (
      <>
        Вечерний азкар №5{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ٩ - اللَّهمَّ مَا أَمْسَى بِي مِنْ نِعْمَةٍ أَوْ بِأحَدٍ مِن خَلْقِكَ
        فَمِنْكَ وَحْدَكَ لا شَرِيكَ لَكَ لَكَ الحَمْدُ ولَكَ الشُّكْرُ
      </>
    ),
    translate: (
      <>
        О Аллах, милости, оказанные мне или кому-либо из Твоих созданий этим
        вечером, исходят только от Тебя Единственного, нет у Тебя сотоварища!
        Только Тебе принадлежит вся хвала и Только Тебе принадлежит вся
        благодарность.
      </>
    ),
    transcription: (
      <>
        Аллахумма ма амсаи би мин нигматин ау бихадин мин холькике фаминке
        ваhдике ля шариика ляка лякаль хамду ва ляка шукур
      </>
    ),
  },
  {
    id: 6,
    Title: () => (
      <>
        Вечерний азкар №6{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ١٠ - اللهمَّ إِنِّي أَسْألُكَ العَافِيةَ فِي الدُّنْيَا والآخِرةِ،
        اللهمَّ إِنِّي أَسْألُكَ العَفْوَ والعَافِيةَ فِي دِينِي ودُنْيَايَ
        وأَهْلِي ومَالي، اللهمَّ استُرْ عوْرتي وآمِنْ رَوْعاتي؛ اللهمَّ احفَظْني
        مِنْ بَيْنِ يَدَيَّ ومِن خَلْفِي وعَنْ يَمِينِي وعَنْ شِمالي ومنْ
        فَوْقِي، وأَعُوذُ بِعَظَمَتِكَ أنْ أُغْتالَ مِنْ تَحْتِي
      </>
    ),
    translate: (
      <>
        О Аллах, поистине, я прошу у Тебя благополучия (избавления от всего
        плохого) в мире этом и в мире вечном! О Аллах, поистине, я прошу у Тебя
        прощения и благополучия (избавления от всего плохого) во всём, что
        касается моей религии и моих мирских дел, моей семьи и моего имущества!
        О Аллах, скрой мою наготу и защити меня от опасности! О Аллах, защити
        меня спереди и сзади, и справа, и слева, и сверху и я прибегаю к Твоему
        величию от того, чтобы быть предательски убитым снизу.
      </>
    ),
    transcription: (
      <>
        Аллахумма, инни ас'алю-кя-ль-'афийата фи-д-дунья ва-ль-ахирати,
        Аллахумма, инни ас'алю-кя-ль-'афуа ва-ль-'афийата фи дини, ва ду-ньяйа,
        ва ахли, ва мали. Аллахумма-стур 'аурати ва-эмин рау'ати,
        Аллахумма-хфаз-ни мин байни йадаййа, ва мин хальфи, ва 'ан ямини, ва 'ан
        шимали ва мин фауки, ва а'узу би-'азамати-кя ан угталя мин тахти!
      </>
    ),
  },
  {
    id: 7,
    Title: () => (
      <>
        Вечерний азкар №7{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        ١٢ - يا حَيُّ يا قيُّومُ برحمتِكَ أستغيثُ أَصْلِحْ لِي شَأْنِي كُلَّهُ
        ولا تَكِلْنِي إِلَى نَفْسِي طَرْفةَ عَينٍ أَبَدًا
      </>
    ),
    translate: (
      <>
        О Живой, о Вечный (о Вседержитель)! Я обращаюсь за защитой к милости
        Твоей, приведи же в порядок все мои дела и никогда не доверяй (не
        оставляй) меня душе моей, пусть даже на мгновение.
      </>
    ),
    transcription: (
      <>
        Йа Хаййу, йа Кайюму, би-рахмати-кя астагису, аслих ли ша'ни кулля-ху ва
        ля такиль-ни иля нафси тарфата 'айнин!
      </>
    ),
  },
  {
    id: 8,
    Title: () => (
      <>
        Аят «аль-Курсий»{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    original: (
      <>
        أَعُوذُ باللهِ السَمِيعِ العَلِيمِ مِنَ الشَّيطانِ الرَّجيمِ اللَّهُ لَا
        إِلَٰهَ إِلَّا هُوَ الْحَيُّ الْقَيُّومُ ۚ لَا تَأْخُذُهُ سِنَةٌ وَلَا
        نَوْمٌ ۚ لَهُ مَا فِي السَّمَاوَاتِ وَمَا فِي الْأَرْضِ ۗ مَنْ ذَا
        الَّذِي يَشْفَعُ عِنْدَهُ إِلَّا بِإِذْنِهِ ۚ يَعْلَمُ مَا بَيْنَ
        أَيْدِيهِمْ وَمَا خَلْفَهُمْ ۖ وَلَا يُحِيطُونَ بِشَيْءٍ مِنْ عِلْمِهِ
        إِلَّا بِمَا شَاءَ ۚ وَسِعَ كُرْسِيُّهُ السَّمَاوَاتِ وَالْأَرْضَ ۖ
        وَلَا يَئُودُهُ حِفْظُهُمَا ۚ وَهُوَ الْعَلِيُّ الْعَظِيمُ
      </>
    ),
    translate: (
      <>
        Я прибегаю к Аллаху Слышащему, Знающему, за защитой от проклятого
        шайтана <br />
        <b>
          «Аллах – нет божества, достойного поклонения, кроме Него, Живого,
          Вечного. Им не овладевают ни дремота, ни сон. Только Ему принадлежит
          то, что на небесах, и то, что на земле. Кто станет заступаться перед
          Ним без Его дозволения?! Он знает их будущее и прошлое. И не постигают
          они из Его знания ничего, кроме того, что Он пожелает. Его Престол
          объемлет небеса и землю, и не отягощает Его оберегание их. Он –
          Возвышенный, Великий»
        </b>{" "}
        (сура «аль-Бакъара», 255).
      </>
    ),
    transcription: (
      <>
        Аллааhу ляя иляяhэ илляя hуваль-хайюль-кайюуум, ляя таэхузуhуу
        синатув-валяя науум, ляhуу маа фис-самааваати ва маа филь ард, мэн
        зал-лязии яшфягу гиндаhуу илляя би изниh, ягляму маа бэйнэ айдииhим ва
        маа хальфахум. Ва ляя юхиитуунэ би шэйим-мин гильмиhи илляя би маа шаа‘,
        васига курсийю hуссамааваати ва маа филь ард, ва ляя я уудуhуу
        хифзухумаа ва hуваль-галийюль-газыиим.
      </>
    ),
  },
  {
    id: 9,
    Title: () => (
      <>
        Чтение "аль Ихляс", "аль Фаляк" и "ан Нас"{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    transcription: <>(Ахмад, абу Дауд, ат Тирмизи, ан Насаи)</>,
  },
  {
    id: 10,
    Title: () => (
      <>
        Вечерний азкар №10{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Три раза</>,
    original: (
      <>
        ٦ - اللهمَّ إِنِّي أَمْسَيْتُ أُشْهِدُكَ وأُشْهِدُ حَمَلَةَ عَرْشِكَ
        ومَلائِكَتَكَ وجَمِيعَ خَلْقِكَ أَنَّكَ أَنْتَ اللهُ لا إله إِلاّ أَنْتَ
        وأنَّ مُحَمَّدًا عَبْدُكَ ورَسُولُكَ
      </>
    ),
    translate: (
      <>
        О Аллах! Поистине, этим вечером я призываю Тебя и тех, кто несёт Твой
        Трон, Твоих ангелов и всех тех, кого Ты создал в свидетели того, что Ты
        - Аллах, нет божества, достойного поклонения, кроме Тебя, и что Мухаммад
        - раб Твой и посланник.
      </>
    ),
    transcription: (
      <>
        Аллахумма инни амсайту ушhидуке ва ушhиду хамалята аршике ва маляиката
        ва джамииа холькике аннака аннта ЛЛаhу Ляя илляhа илля Анта ва анна
        Мухаммадан абдука ва расулюка
      </>
    ),
  },
  {
    id: 11,
    Title: () => (
      <>
        Вечерний азкар №11{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Три раза</>,
    original: (
      <>
        ٧ - باسمِ اللهِ الَّذِي لا يَضُرُّ مَعَ اسْمِهِ شيءٌ فِي الأَرْضِ ولا في
        السَّمَاءِ وهو السَمِيعُ العَلِيمُ
      </>
    ),
    translate: (
      <>
        С именем Аллаха, с которым ничто не властно навредить ни на земле, ни на
        небесах, и Он - Слышащий, Знающий.
      </>
    ),
    transcription: (
      <>
        Би-сми-Лляхи аллязи ля йадурру ма'а исми-хи шайун фи-ль-арди ва ля
        фи-с-самаи ва хуа-с-Сами'у-ль-'Алиму
      </>
    ),
  },
  {
    id: 12,
    Title: () => (
      <>
        Вечерний азкар №12{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Три раза</>,
    original: (
      <>
        ١٥ - أَمْسَيْنا وأَمْسَى المُلْكُ لله رَبِّ العَالَمِينَ اللهمَّ إِنِّي
        أَسْألُك خَيْرَ هَذه الليلة فَتْحَها ونَصْرَهُا ونَورَها وبَرَكَتَهُا
        وهُدَاها وأَعُوذُ بِكَ مِنْ شَرِّ ما فِيها وشَرِّ ما بَعْدَها
      </>
    ),
    translate: (
      <>
        Мы встретили вечер, и встретило вечер всё, что подвластно Аллаху,
        Господу миров. О Аллах, поистине, я прошу Тебя о благе этой ночи, о
        помощи и победе в эту ночь, о свете её и благодати, о прямом руководстве
        в эту ночь, и я прибегаю к защите Твоей от зла того, что случится этой
        ночью, и зла того, что за ней последует.
      </>
    ),
    transcription: (
      <>
        амсайнаа ва амсаль мульку ЛЛаhи роббиль Алямиин  Аллахумма инни ас алюка
        хойро hазаль ляйлята фатхаhа ва насроhу ванауроhа ва барокатаhу ва
        hудааhаа ва аузу бика минн шарри маа фиhаа ва шарри маа багдаhaа
      </>
    ),
  },
  {
    id: 13,
    Title: () => (
      <>
        Вечерний азкар №13{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Три раза</>,
    original: (
      <>
        ١٦- اللَّهمَّ عافني في بدني ، اللَّهمَّ عافني في سمعي ، اللَّهمَّ عافني
        في بصري ، لا إلهَ إلَّا أنت اللَّهمَّ إنِّي أعوذُ بك من الكفرِ والفقرِ ،
        اللَّهمَّ إنِّي أعوذُ بك من عذابِ القبرِ، لا إلهَ إلَّا أنت
      </>
    ),
    translate: (
      <>
        О Аллах, отдали от грехов, и избавь от наказания моё тело и, о Аллах,
        отдали от грехов и избавь от наказания мой слух, о Аллах, отдали от
        грехов и избавь от наказания моё зрение. Нет божества, достойного
        поклонения, кроме Тебя. О Аллах, поистине, я прибегаю к защите Твоей от
        неверия и от бедности, о Аллах, поистине, я прибегаю к защите Твоей от
        мучений могилы. Нет божества, достойного поклонения, кроме Тебя.
      </>
    ),
    transcription: (
      <>
        Аллахумма аафани фии бадани <br />
        Аллахумма аафани фи самаи <br />
        Аллахумма аафани фи басари <br />
        ляя иляха иля Анта <br />
        Аллахумма инни аузу бикя миннель куфри валь факри <br />
        Аллахумма инни аузу бике мин азабль кабри ляя иляха иля Ант
      </>
    ),
  },
  {
    id: 14,
    Title: () => (
      <>
        Вечерний азкар №14{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Три раза</>,
    original: (
      <>٢٢ - أَعُوذُ بِكَلِمَات الله التَّامَّات مِنْ شَرِّ مَا خَلَقَ</>
    ),
    translate: (
      <>
        Я прибегаю к защите совершенных Слов Аллаха от зла того, что Он
        сотворил.
      </>
    ),
    transcription: <>А'узу би-кялимати Лляхи-т-таммати мин шарри ма халяка.</>,
  },
  {
    id: 15,
    Title: () => (
      <>
        Вечерний азкар №15{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Три раза</>,
    original: (
      <>
        ٨ - رَضِيْتُ بِاللهِ ربًّا وبِالإسْلامِ دِينًا وبِمُحَمَّدٍ ﷺ نَبِيًّا
      </>
    ),
    translate: (
      <>
        Доволен я Аллахом - как Господом, Исламом - как религией и Мухаммадом ﷺ
        - как пророком.
      </>
    ),
    transcription: (
      <>
        Радийту би-Лляхи Раббан, ва би-ль-ислами динан ва би-Мухаммадин,
        сал-ля-Ллаху 'аляй-хи ва салляма, набийан
      </>
    ),
  },
  {
    id: 16,
    Title: () => (
      <>
        Вечерний азкар №16{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Семь раз</>,
    original: (
      <>
        ١٧ - حَسبيَ اللَّهُ لا إلهَ إلَّا هوَ ، علَيهِ تَوَكَّلتُ وَهوَ ربُّ
        العرشِ العظيمِ
      </>
    ),
    translate: (
      <>
        Достаточно мне Аллаха, нет божества, достойного поклонения, кроме Него,
        только на Него я уповаю, и Он - Господь Великого Трона.
      </>
    ),
    transcription: (
      <>
        Хасбия-Ллаху, ля иляха илля хуа, 'аляй-хи таваккяльту ва хуа
        Раббу-ль-'арши-ль-'азыми
      </>
    ),
  },
  {
    id: 17,
    Title: () => (
      <>
        Вечерний азкар №17{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Десять раз</>,
    original: (
      <>
        ٢٤ - اللَّهمَّ صَلِّ عَلَى مُحَمَّدٍ وعَلَى آلِ مُحَمَّدٍ ، كَمَا
        صَلَّيْتَ عَلَى إِبْرَاهِيمَ وآلِ إِبْرَاهِيمَ إنَّكَ حَمِيدٌ مَجِيدٌ
        اللهُمَّ بارِكْ عَلَى مُحَمَّدٍ وعَلَى آلِ مُحَمَّدٍ ، كَمَا بارَكتَ
        عَلَى إِبْرَاهِيمَ وآلِ إِبْرَاهِيمَ إنَّكَ حَمِيدٌ مَجِيدٌ
      </>
    ),
    translate: (
      <>
        О Аллах, восхвали Мухаммада и последователей Мухаммада, так же, как Ты
        восхвалил Ибрахима и последователей Ибрахима, поистине Ты – Хвалимый,
        Славный (Прославляемый)! О Аллах, даруй благодать Мухаммаду и
        последователям Мухаммада, так же, как Ты даровал благодать Ибрахиму и
        последователям Ибрахима, поистине Ты – Хвалимый, Славный
        (Прославляемый).
      </>
    ),
    transcription: (
      <>
        Аллахумма сали аля Мухаммадин ва аля аали Мухаммад, кама солейта аля
        Иброхима ва аля али Иброхима иннака хамиидум маджиид Аллахумма барик аля
        Мухаммадин ва аля аали Мухаммад кама барокта аля Иброхима ва аля аали
        Иброхима иннака хамиидум маджиид
      </>
    ),
  },
  {
    id: 18,
    Title: () => (
      <>
        Вечерний азкар №18{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    custom: true,
    counter: <>Сто раз. Если прибавить к этому, то будет лучше</>,
    original: <>٢١ - سُبْحَانَ اللَّهِ</>,
    translate: <>Пречист Аллах (от всех недостатков).</>,
    transcription: <>Субhaн Аллаh</>,
    original1: <>الحَمْدُ للهِ</>,
    translate1: <>Хвала Аллаху.</>,
    transcription1: <>альхамду ЛиЛЛЯh</>,
    original2: <>الله أَكْبَرُ.</>,
    translate2: <>Аллах Велик (Превыше всего).</>,
    transcription2: <>Аллаhу Акбар</>,
    original3: (
      <>
        لا إلهَ إلَّا اللهُ وَحْدَهُ لا شَرِيكَ لَهُ، لَهُ المُلْكُ ولَهُ
        الحَمْدُ، يُحْيِي ويُمِيتُ، وهُوَ عَلَى كُلِّ شَيْءٍ قَدِيرٌ
      </>
    ),
    translate3: (
      <>
        Нет божества, достойного поклонения, кроме Аллаха, Единственного, у
        Которого нет сотоварища. Только Ему принадлежит вся власть и только Ему
        принадлежит вся хвала, и Он над каждой вещью мощен.
      </>
    ),
    transcription3: (
      <>
        Ля иляха илля-Ллаху вахда-ху ля шарикя ля-ху, ля-ху-ль-мульку ва
        ля-ху-ль-хамду ва хуа 'аля кулли шайин кадир.
      </>
    ),
  },
  {
    id: 19,
    Title: () => (
      <>
        Вечерний азкар №19{" "}
        <b
          className="footnoteLink"
          onClick={handleClick}
          style={{ color: "#DF0000" }}
        >
          (1)*
        </b>
      </>
    ),
    counter: <>Сто раз</>,
    original: (
      <>
        ٢٣ - سُبحانَ اللَّهِ وبِحَمْدِهِ أَوْ: سُبحانَ اللَّهِ العظيم
        وبِحَمْدِهِ
      </>
    ),
    translate: (
      <>
        Пречист Аллах (от всех недостатков) и хвала Ему. Или: Пречист Великий
        Аллах (от всех недостатков) и хвала Ему.
      </>
    ),
    transcription: <>Субхана-Ллахи ва би-хамди-хи.  Субхана-Ллахиль Газыйм</>,
  },
];

export default morning_azkaras;
