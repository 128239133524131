import { useGlobalContext } from "../context";

import ablutions from "../arrays/ablutions";

import scrollDark from "../assets/scrollDark.svg";
import scrollMobDark from "../assets/scrollMobDark.svg";
import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import NavBarDarkOnly from "./NavBarDarkOnly";
import SingleAblution from "./SingleAblution";
import Footer from "./Footer";
import MenuModal from "./MenuModal";

import "../styles/Ablution.scss";
import { Link } from "react-router-dom";

function Ablution() {
  const { isDarkTheme, setIntroductionAblutionNamazBack } = useGlobalContext();

  return (
    <>
      <header className="header header-ablution">
        <div className="navBar-ablution">
          <NavBarDarkOnly />
        </div>
        <div className="headerWrapper headerWrapper-ablution">
          <span className="header__title header__title-dark">Омовение</span>
          <div className="header__vertLine header__vertLine-dark header__vertLine-ablution"></div>
          <img
            className="header__scroll-desc"
            src={scrollDark}
            alt="scroll-desc"
          />
          <img
            className="header__scroll-mobile"
            src={scrollMobDark}
            alt="scroll-mobile"
          />
        </div>
      </header>
      <div className={`${isDarkTheme ? `content content-dark` : `content`}`}>
        <div className="contentWrapper">
          <Link
            to={`/introduction_ablution&namaz`}
            onClick={setIntroductionAblutionNamazBack("ablution")}
            className={`${
              isDarkTheme
                ? `content__introductionBtn content__introductionBtn-dark`
                : `content__introductionBtn`
            }`}
          >
            Открыть вступление
          </Link>
          <div className="content__ablution">
            {ablutions.map((item) => (
              <SingleAblution key={item.id} {...item}></SingleAblution>
            ))}
          </div>
          <button
            className={`${
              isDarkTheme
                ? `content__returnBtn content__returnBtn-dark`
                : `content__returnBtn`
            }`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <img
              src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
              alt="arrowTop"
            />
          </button>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default Ablution;
