import { useRef, useState } from "react";

import Track from "./Track";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";

import "./AudioPlayer.scss";

const AudioPlayer = ({ audioSrc }) => {
  // states
  const [trackIndex, setTrackIndex] = useState(0);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  // reference
  const audioRef = useRef();
  const progressBarRef = useRef();

  return (
    <>
      <Track
        {...{
          audioSrc,
          audioRef,
          setDuration,
          progressBarRef,
        }}
      />
      <div className="audio-player">
        <Controls
          {...{
            audioRef,
            progressBarRef,
            duration,
            setTimeProgress,
            trackIndex,
            setTrackIndex,
          }}
        />
        <ProgressBar
          {...{ progressBarRef, audioRef, timeProgress, duration }}
        />
      </div>
    </>
  );
};
export default AudioPlayer;
