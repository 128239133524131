import morning_footnotes from "./morning_footnotes";

export const data_morning = morning_footnotes.reduce(function (
  result,
  footnote
) {
  return {
    ...result,
    [footnote.id]: {
      Title: footnote.Title,
      Text: footnote.Text,
    },
  };
},
{});
