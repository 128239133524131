import { useGlobalContext } from "../context";

import "../styles/Menu.scss";

import NavBar from "./Navbar";
import Footer from "./Footer";
import MenuModal from "./MenuModal";
import { Link } from "react-router-dom";

function Books() {
  const { isDarkTheme } = useGlobalContext();

  return (
    <>
      <div
        className={`${isDarkTheme ? `navBarIntro-dark` : `navBarIntro-light`}`}
      >
        <NavBar />
      </div>
      <div
        className={`${
          isDarkTheme
            ? `content content-menu content-dark `
            : `content content-menu`
        }`}
      >
        <div className="contentWrapper contentWrapper-menu">
          <Link
            className={`${
              isDarkTheme
                ? `menu__block menu__morningAzkaras menu__morningAzkaras-dark`
                : `menu__block menu__morningAzkaras`
            }`}
            to={"./morning_azkaras"}
          >
            <div
              className={`${
                isDarkTheme ? `menuTitle menuTitle-dark` : `menuTitle`
              }`}
            >
              Утренние азкары
            </div>
            <div
              className={`${
                isDarkTheme ? `menuLine menuLine-dark` : `menuLine`
              }`}
            ></div>
          </Link>
          <Link
            className={`${
              isDarkTheme
                ? `menu__block menu__eveningAzkaras menu__eveningAzkaras-dark`
                : `menu__block menu__eveningAzkaras`
            }`}
            to={"./evening_azkaras"}
          >
            <div className="menuTitle menuTitle-dark">Вечерние азкары</div>
            <div className="menuLine menuLine-dark"></div>
          </Link>
          <Link
            className={`${
              isDarkTheme
                ? `menu__block menu__block-small menu__block-middle menu__namaz menu__namaz-dark`
                : `menu__block menu__block-small menu__block-middle menu__namaz`
            }`}
            to={"./namaz"}
          >
            <div className="menuTitle menuTitle-dark">Намаз</div>
            <div className="menuLine menuLine-dark"></div>
          </Link>
          <Link
            className={`${
              isDarkTheme
                ? `menu__block menu__block-small menu__block-middle menu__ablution menu__ablution-dark`
                : `menu__block menu__block-small menu__block-middle menu__ablution`
            }`}
            to={"./ablution"}
          >
            <div className="menuTitle menuTitle-dark">Омовение</div>
            <div className="menuLine menuLine-dark"></div>
          </Link>
          <Link
            className={`${
              isDarkTheme
                ? `menu__block menu__block-middle menu__books menu__books-dark`
                : `menu__block menu__block-middle menu__books`
            }`}
            to={"./books"}
          >
            <div className="menuTitle menuTitle-dark">Книги</div>
            <div className="menuLine menuLine-dark"></div>
          </Link>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default Books;
