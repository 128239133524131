import { useGlobalContext } from "../context";

import evening_azkaras from "../arrays/evening_azkaras";
// import evening_footnotes from "../arrays/evening_footnotes";

// import scrollLight from "../assets/scrollLight.svg";
import scrollDark from "../assets/scrollDark.svg";
// import scrollMobLight from "../assets/scrollMobLight.svg";
import scrollMobDark from "../assets/scrollMobDark.svg";
import arrowRight from "../assets/arrow_right.svg";
import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import NavBar from "./Navbar";
import Footer from "./Footer";
import SingleAzkar from "./SingleAzkar";
import MenuModal from "./MenuModal";
// import SingleFootnote from "./SingleFootnote";

import "../styles/Azkaras.scss";
import { Link } from "react-router-dom";

function AzkarasEvening() {
  const { isDarkTheme, setIntroductionAzkarasBack } = useGlobalContext();

  return (
    <>
      <header
        className={`${
          isDarkTheme ? `header evening evening-dark` : `header evening`
        }`}
      >
        <NavBar />
        <div className="headerWrapper">
          <span className="header__title header__title-dark">
            Вечерние азкары
          </span>
          <div className="header__vertLine header__vertLine-dark"></div>
          <div className="header__desc header__desc-dark">
            Их время начинается с завершением послеполуденный молитвы (аль-‘аср)
            и продолжается до захода солнца
          </div>
          <Link to={`/`} className="header__btn">
            Утренние азкары
            <img src={arrowRight} alt="arrowRight" />
          </Link>
          <img
            className="header__scroll-desc"
            src={scrollDark}
            alt="scroll-desc"
          />
          <img
            className="header__scroll-mobile"
            src={scrollMobDark}
            alt="scroll-mobile"
          />
        </div>
      </header>
      <div className={`${isDarkTheme ? `content content-dark` : `content`}`}>
        <div className="contentWrapper">
          <Link
            to={`/introduction_azkaras`}
            onClick={setIntroductionAzkarasBack("evening_azkaras")}
            className={`${
              isDarkTheme
                ? `content__introductionBtn content__introductionBtn-dark`
                : `content__introductionBtn`
            }`}
          >
            Открыть вступление
          </Link>
          <div className="content__azkaras">
            {evening_azkaras.map((item) => (
              <SingleAzkar key={item.id} {...item}></SingleAzkar>
            ))}
          </div>
          {/* <div
            className={`${
              isDarkTheme
                ? `content__footnotes content__footnotes-dark`
                : `content__footnotes`
            }`}
          >
            {evening_footnotes.map((item) => (
              <SingleFootnote key={item.id} {...item}></SingleFootnote>
            ))}
          </div> */}
          <button
            className={`${
              isDarkTheme
                ? `content__returnBtn content__returnBtn-dark`
                : `content__returnBtn`
            }`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <img
              src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
              alt="arrowTop"
            />
          </button>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default AzkarasEvening;
