import { useGlobalContext } from "../context";

import AudioPlayer from "../audio_player/AudioPlayer";
import SocialLinks from "../components/SocialLinks";

import "../styles/SingleAblution.scss";

function SingleAblution(props) {
  const { isDarkTheme } = useGlobalContext();

  let original;
  if (props.original) {
    original = (
      <>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
        <div className="azkar__original">{props.original}</div>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
      </>
    );
  }

  let translate;
  if (props.translate) {
    translate = <div className="ablution__translate">{props.translate}</div>;
  }

  let audioPlayer;
  if (props.audioPlayer) {
    audioPlayer = (
      <div className="azkar__player">
        <AudioPlayer audioSrc={props.audio}></AudioPlayer>
      </div>
    );
  }

  return (
    <>
      <div className={`${isDarkTheme ? `azkar azkar-dark` : `azkar`}`}>
        <div className="ablution__img">
          <img
            className={props.img_className}
            src={`${isDarkTheme ? props.img_dark : props.img_light}`}
            alt="ablution_img"
          />
        </div>
        <div
          className={`${
            isDarkTheme
              ? `ablution__title ablution__title-dark`
              : `ablution__title`
          }`}
        >
          {props.title}
        </div>
        <div className="ablution__text">{props.text}</div>
        {original}
        {translate}
        {audioPlayer}
        {SocialLinks}
      </div>
    </>
  );
}

export default SingleAblution;
