import "../styles/NavBar.scss";

import { useGlobalContext } from "../context";
import { useState, useEffect } from "react";

import logoLight from "../assets/logoLight.png";
import logoDark from "../assets/LogoDark.png";
import flag_rus from "../assets/flag_rus.svg";
import arrowsLight from "../assets/arrowsLight.svg";
import arrowsDark from "../assets/arrowsDark.svg";
import searchLight from "../assets/searchLight.svg";
import searchDark from "../assets/searchDark.svg";
import burgerBtnDark from "../assets/burgerBtnDark.svg";
import burgerBtnLight from "../assets/burgerBtnLight.svg";
import shareLight from "../assets/shareLight.svg";
import shareDark from "../assets/shareDark.svg";

import { Link } from "react-router-dom";

function NavBar() {
  const { isDarkTheme, setIsDarkTheme, setModalMenuOpen } = useGlobalContext();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  let themeText;
  if (windowWidth >= 1024) {
    themeText = "Тёмная тема";
  } else {
    themeText = "Тёмная";
  }

  let checker;
  if (isDarkTheme) {
    checker = true;
  } else {
    checker = false;
  }

  return (
    <div className={`${isDarkTheme ? "navBar navBar-dark" : "navBar"}`}>
      <Link to={"/"}>
        <img
          src={`${isDarkTheme ? logoDark : logoLight}`}
          alt="navBar__logo"
          className="navBar__logo"
        />
      </Link>
      <div className="navBarWrapper">
        <div className="navBar__language">
          <div className="navBar__languageWrapper">
            <img className="navBar__language__flag" src={flag_rus} alt="flag" />
            <span className="navBar__language__text">Русский</span>
            <img
              src={`${isDarkTheme ? arrowsDark : arrowsLight}`}
              alt="arrows_btn"
            />
          </div>
          <div>{/* место под компонент со списком выбора языка */}</div>
        </div>
        <div className="navBar__theme">
          {themeText}
          <label class="custom-switcher">
            <input
              type="checkbox"
              class="custom-checkbox"
              onClick={() => setIsDarkTheme(!isDarkTheme)}
              checked={checker}
            />
            <span class="custom-slider"></span>
          </label>
        </div>
        <div className="navBar__share">
          <img src={`${isDarkTheme ? shareDark : shareLight}`} alt="share" />
          <div className="navBar__share__text">Поделиться разделом</div>
        </div>
        <button className="navBar__search">
          <img src={`${isDarkTheme ? searchDark : searchLight}`} alt="search" />
        </button>
        <button className="navBar__burger">
          <img
            onClick={() => setModalMenuOpen(true)}
            src={`${isDarkTheme ? burgerBtnDark : burgerBtnLight}`}
            alt="burgerBtn"
          />
        </button>
      </div>
    </div>
  );
}

export default NavBar;
