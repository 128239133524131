import "../styles/Footer.scss";

import logoLight from "../assets/logoLight.png";
import logoDark from "../assets/LogoDark.png";

import { useGlobalContext } from "../context";
import { useState, useEffect } from "react";
import NavBar from "./Navbar";

import { Link } from "react-router-dom";

function Footer() {
  const { isDarkTheme } = useGlobalContext();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const divider = () => {
    if (windowWidth < 1024) {
      return <br />;
    }
  };

  return (
    <footer className={`${isDarkTheme ? `footer footer-dark` : `footer`}`}>
      <NavBar></NavBar>
      <div className="footerBot">
        <Link to={"/"}>
          <img
            className="footer__logo"
            src={`${isDarkTheme ? logoDark : logoLight}`}
            alt="footer__logo"
          />
        </Link>
        <div
          className={`${
            isDarkTheme ? `footer__text footer__text-dark` : `footer__text`
          }`}
        >
          © Азкары, 2023 <br /> Разработка сайта -{divider()} компания
          «Технополюс»
        </div>
      </div>
    </footer>
  );
}

export default Footer;
