import "../styles/FootnotesModal.scss";
import modal_close_dark from "../assets/modal_close_dark.svg";
import modal_close_light from "../assets/modal_close_light.svg";
import { data_morning } from "../arrays/data_morning";

import { useGlobalContext } from "../context";

function FootnotesModalMorning() {
  const { isDarkTheme, modalMorning, modalMorningOpen, setModalMorningOpen } =
    useGlobalContext();

  if (modalMorningOpen) {
    return (
      <>
        <div
          className={`${
            isDarkTheme ? `footnoteModal footnoteModal-dark` : `footnoteModal`
          }`}
        >
          <div className="footnoteModalWrapper">
            <div
              className={`${
                isDarkTheme
                  ? `footnoteModal__container footnoteModal__container-dark`
                  : `footnoteModal__container`
              }`}
            >
              <button
                className="footnoteModal__close"
                onClick={() => setModalMorningOpen(false)}
              >
                <img
                  src={isDarkTheme ? modal_close_dark : modal_close_light}
                  alt="modal_close"
                />
              </button>
              {modalMorning.map((item) => {
                const { Text, Title } = data_morning["f" + item];
                return (
                  <>
                    <div className="footnoteModalBox">
                      <div
                        className={`${
                          isDarkTheme
                            ? `footnoteModal__title footnoteModal__title-dark`
                            : `footnoteModal__title`
                        }`}
                      >
                        <Title />
                      </div>
                      <div
                        className={`${
                          isDarkTheme
                            ? `footnoteModal__line footnoteModal__line-dark`
                            : `footnoteModal__line`
                        }`}
                      ></div>
                      <div
                        className={`${
                          isDarkTheme
                            ? `footnoteModal__text footnoteModal__text-dark`
                            : `footnoteModal__text`
                        }`}
                      >
                        <Text />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FootnotesModalMorning;
