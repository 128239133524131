const morning_footnotes = [
  {
    id: "f1",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(1)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его имам Муслим в своём сборнике «ас-Сахих» (2723), Абу Дауд
        (5071), ат-Тирмизи (3390), имам Ахмад (4192) от ‘абдуЛлаха ибн Мас’уда
        (да будет доволен им Аллах), который сказал:{" "}
        <b>
          «Пророк Аллаха ﷺ, когда наступал вечер, говорил: «…», и когда
          наступало утро, говорил: «…»».
        </b>
      </>
    ),
  },
  {
    id: "f2",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(2)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его имам аль-Бухари в «Адаб аль-Муфрад» (1199), Абу Дауд
        (5068), ат-Тирмизи (3391), Ибн Маджа (3868), Ибн Хиббан (2354), имам
        Ахмад (2/354), аль-Хайсами (10/114), аль-Багъауи (1325/5/112), от Абу
        Хурэйры, который передал, что Пророк ﷺ, встретив утро, говорил:
        <b> «О Аллах, поминая Тебя мы встретили утро...».</b>
      </>
    ),
  },
  {
    id: "f3",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(3)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его имам аль-Бухари в своём сборнике «ас-Сахих» (5947),
        ан-Насаи (5963), ат-Табарани в «аль-Кябир» (7/7173), от Шаддада ибн Ауса
        (да будет доволен им Аллах) от Пророка ﷺ, который сказал:{" "}
        <b>
          «Наилучшая мольба о прощении: «…», затем сказал: «Тот, кто произнесёт
          её днём, будучи убеждённым в том, что говорит, и умрёт до наступления
          вечера, будет из обитателей Рая. А тот, кто произнесёт её вечером,
          будучи убеждённым в том, что говорит, и умрёт до наступления утра,
          будет из обитателей Рая».
        </b>
      </>
    ),
  },
  {
    id: "f4",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(4)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его ат-Тирмизи (3529), имам аль-Бухари в «Адаб аль-Муфрад»
        (1204), согласно версии Абу Рашида аль-Хубрани, в которой сообщается,
        что Абу Бакр ас-Сыддыкъ (да будет доволен им Аллах) попросил у
        Посланника Аллаха ﷺ: «О Посланник Аллаха, научи меня тому, что следует
        говорить мне, когда я встречу утро и когда встречу вечер». И он ответил:{" "}
        <b>«О Абу Бакр, говори: «О Аллах, Создатель небес и земли…»». </b>
        Усиливал достоверность этого хадиса аль-Хафиз ибн Хаджар (да будет
        милостив к нему Аллах) в «Натаиджу аль-Афкар» (2/345-346). Сказал шейх
        аль-Альбани (да будет милостив к нему Аллах): «Цепь его передатчиков
        достоверная».
      </>
    ),
  },
  {
    id: "f5",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(5)</span> - От переводчика
      </>
    ),
    Text: () => (
      <>
        Имя Аллаха <b>«аль-Ахад» – «Единственный», </b> у Которого нет
        сотоварища и соучастника, у Которого нет похожего и нет подобного. Как
        сказал шейх ас-Са’ди (да будет милостив к нему Аллах): «Единственный –
        тот, Который уединился во всём совершенном, у Которого нет в этом
        сотоварища. Поэтому является обязательным для рабов уединять Его в своих
        убеждениях (сердцах), словах и делах, зная о том, что Он обладает
        абсолютным совершенством и что оно принадлежит только Ему Одному. А
        также уединять Его во всех видах поклонения». См.: «Тайсир аль-Карим
        ар-Рахман» (5/298,299).
      </>
    ),
  },
  {
    id: "f6",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(6)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводят его ат-Тирмизи (3575), Абу Дауд (5082), ан-Насаи (5443), имам
        Ахмад (5312), от Му’аза ибн ‘АбдиЛлях ибн Хъубэйба, который передал от
        своего отца, который сказал: «… сказал: Читай по три раза «Скажи Он
        Аллах Единственный» и две последние суры, когда встретишь вечер и когда
        встретишь утро, и этого тебе будет достаточно для избавления от всего».
      </>
    ),
  },
  {
    id: "f7",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(7)</span> - Хадис хороший, согласно
        другим, подкрепляющим его, версиям
      </>
    ),
    Text: () => (
      <>
        Привёл его Абу Дауд в «ас-Сунан» (5069):
        <b>
          {" "}
          «Того, кто встретив утро или вечер, скажет: «…», - Аллах освободит от
          Огня на четверть. Того, кто скажет эти слова дважды, Аллах освободит
          от Огня наполовину. Того, кто скажет их трижды, Аллах освободит от
          Огня на три четверти. Того же, кто скажет их четырежды, Всевышний
          Аллах освободит от огня полностью».
        </b>{" "}
        <br />В версии у ат-Тирмизи (3501) приводится, что Пророк ﷺ, после того,
        как упомянул эту мольбу, сказал:{" "}
        <b>
          «Того, кто произнесёт эти слова утром, тому Аллах обязательно простит
          то, что он совершит из прегрешений в этот день, а того, кто произнесёт
          их вечером, тому Аллах обязательно простит то, что он совершит из
          прегрешений в эту ночь».
        </b>
      </>
    ),
  },
  {
    id: "f8",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(8)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его ат-Тирмизи (3385), Абу Дауд (5088, 5089), Ибн Маджа (3869),
        Ибн Хиббан в «ас-Сахих» (2352) в сокращенном варианте, имам Ахмад в
        «аль-Муснад» (1/62,66), все вместе они приводят версию от Абаана ибн
        ‘Усмана ибн ‘Аффана, который сказал, что слышал как ‘Усман – т.е. Ибн
        ‘Аффан – говорил: «Я слышал, как Посланник Аллаха ﷺ говорил:{" "}
        <b>
          «Кто скажет: «…», - того не постигнет нежданная беда до самого утра, а
          тот, кто произнесет их трижды утром, того не постигнет нежданная беда
          до самого вечера».
        </b>{" "}
        Затем (передатчик этого хадиса) сказал: «Однажды Абаана ибн ‘Усмана
        постиг паралич, и человек, который слышал от него этот хадис, с
        удивлением посмотрел на него. Тогда (Абаана) спросил его: «Почему ты так
        смотришь на меня? Ведь клянусь Аллахом, я не солгал на ‘Усмана и ‘Усман
        не солгал на Пророка ﷺ! Однако сегодня, в день, в котором меня постигло
        то, что постигло, я был разгневан и забыл произнести эти слова». Шейх
        аль-Альбани в «Сахих аль-Джами’» (5621) сказал: «Хадис достоверный».
      </>
    ),
  },
  {
    id: "f9",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(9)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его ан-Насаи (2/57), имам Ахмад (3/14), аль-Бэйхакъый (9/158),
        Ибн Хиббан (4593) от Абу Са’ида аль-Хъудри, который сказал: «Сказал
        Посланник Аллаха ﷺ:{" "}
        <b>«Кто скажет: «…», - тот обязательно окажется в Раю»».</b>{" "}
        Достоверность этого хадиса подтвердил шейх аль-Альбани в «ас-Сахиха»
        (334) и в других сборниках.
      </>
    ),
  },
  {
    id: "f10",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(10)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводят его Абу Дауд (5073), Ибн Хиббан (2361), от ‘АбдуЛлаха ибн
        Гъаннама, который передал, что Посланник Аллаха ﷺ сказал:{" "}
        <b>
          «Кто, встретив утро, скажет: «…», - тот должным образом выразит свою
          благодарность (Аллаху), которую следует выразить в этот день, а тот,
          кто скажет эти слова вечером, тот должным образом выразит
          благодарность (Аллаху), которую следует выразить в эту ночь».
        </b>{" "}
        Хорошую степень этого хадиса подтвердил аль-Хафиз ибн Хаджар (да будет
        милостив к нему Аллах) в толковании книги «аль-Азкар» (3/107). Также
        хорошую степень пути передачи (иснада) этого хадиса подтвердил шейх Ибн
        Баз. См.: «Тухфату аль-Ахъяр» (стр. 24).
      </>
    ),
  },
  {
    id: "f11",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(11)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводят его Абу Дауд (5074), имам Ахмад в «аль-Муснад» (2/25), Ибн
        Маджа (3871), Ибн Хиббан в «ас-Сахих» (2356), аль-Хаким (1/517),
        ан-Насаи (8/282) сокращенно, от ‘АбдуЛлаха ибн ‘Умара, который сказал:{" "}
        <b>
          «Не было такого случая, чтобы Пророк ﷺ, встретив вечер или утро,
          оставил следующие слова: «…»».
        </b>{" "}
        Достоверность хадиса подтвердил аль-Хаким, с ним в этом согласился
        аз-Захаби. Сказал аль-Хафиз в «Амали аль-Азкар»: «Хадис хороший», как
        передаётся об этом в «аль-Футухат ар-Раббания» у Ибн ‘Алляна (3/108).
        Также достоверность его подтвердил шейх аль-Альбани в «Сахих
        аль-Мауарид» (2/424/2356) и др.
      </>
    ),
  },
  {
    id: "f12",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(12)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводят его Абу Дауд (5077), Ибн Маджа (2/1272/3867), имам Ахмад в
        «аль-Муснад» (4/60), ан-Насаи в «’Амаль аль-йауми уа аль-лейля» (27), от
        Абу ‘Айаша аз-Зуракъы, который сообщил, что Посланник Аллаха ﷺ сказал:{" "}
        <b>
          «Тому, кто встретив утро, скажет: «…», - запишется награда, подобная
          освобождению раба из числа потомков Исма‘иля, и запишется ему десять
          наград, и будут стёрты у него десять прегрешений, и будет он возвышен
          на десять степеней, и будет он защищён от шайтана в этот день до
          самого вечера. Если же человек произнесёт эти слова вечером, будет ему
          дано то же самое до наступления утра».
        </b>
      </>
    ),
  },
  {
    id: "f13",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(13)</span> - От переводчика
      </>
    ),
    Text: () => (
      <>
        Имя Аллаха: <b> «аль-Къайюм» – «Вечный»</b>, т.е. существующий вечно,
        без начала и конца. Также одним из его значений является:{" "}
        <b> «Вседержитель» - Управляющий делами Своих творений</b>, как об этом
        говорили Абу ‘Убэйда, ат-Табари, Ибн Хаджар, аль-Хъаттаби, аль-Бэйхакъы
        и др. См.: «Нахджу аль-Асма» (стр. 360).
      </>
    ),
  },
  {
    id: "f14",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(14)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводят его аль-Хаким (1/545), Ибн ас-Сунний (48), от Анаса ибн Малика
        (да будет доволен им Аллах), который сообщил, что Посланник Аллаха ﷺ
        сказал Фатыме:{" "}
        <b>
          {" "}
          «Что мешает тебе выслушать моё наставление, которое я дам тебе? Когда
          встретишь утро и когда встретишь вечер, говори: «…»».
        </b>{" "}
        Сказал аль-Хаким: «Хадис достоверный, согласно условиям двух имамов
        (аль-Бухари и Муслима)». С ним в этом согласился аз-Захаби. Также
        достоверность его подтвердил шейх аль-Альбани в «Сахих ат-Таргъиб уа
        ат-Тархиб» (1/417/661).
      </>
    ),
  },
  {
    id: "f15",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(15)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его ан-Насаи в «’Амалю аль-йауми уа аль-лейля» (960),
        ат-Табарани в «аль-Кябир» (541), от Убэйа ибн Ка’ба, которому джин
        однажды сказал: «…, кто произнесёт этот аят когда встретит утро, тот
        будет защищён от нас до наступления вечера». Когда наступило утро, он
        пришёл к Посланнику Аллаха ﷺ и рассказал об этом. На что он ﷺ ответил:{" "}
        <b>«Истину сказал скверный».</b>
      </>
    ),
  },
  {
    id: "f16",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(16)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его имам Ахмад (5/420), ат-Табарани (3883), от Абу Айюба
        аль-Ансари (да будет доволен им Аллах), который, находясь на землях
        Римской Империи, говорил: «Поистине Посланник Аллаха ﷺ сказал:{" "}
        <b>
          «Кто скажет утром: «…», тому Аллах запишет десять наград, удалит от
          него десять прегрешений, и это будет для него подобно награде десяти
          отпущенных на свободу рабов, и Аллах даст ему защиту от шайтана. Если
          же человек скажет эти слова вечером, будет ему то же самое».
        </b>{" "}
        <br />
        Достоверность этого хадиса подтвердил шейх аль-Альбани в «ас-Сахиха»
        (2563).
      </>
    ),
  },
  {
    id: "f17",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(17)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его Муслим (2090), Абу Дауд (1503), ат-Тирмизи (3555), ан-Насаи
        (1351), Ибн Маджа (3808), от Ибн ‘Аббаса (да будет доволен им Аллах),
        который сказал: «Однажды Посланник Аллаха ﷺ вышел от Джуэйрии, – которую
        звали Барра, и которой (Пророк Аллаха) поменял имя, - в то время как она
        совершала молитву. Когда же он вернулся обратно, то застал её до сих пор
        взывающей к Аллаху, и сказал ей:{" "}
        <b> «Ты до сих пор совершаешь молитву?»</b> На что она ответила: «Да».
        Тогда он сказал:{" "}
        <b>
          {" "}
          «Выйдя от тебя, я трижды повторил четыре слова, и если сравнить их по
          весу со всем тем, что говорила ты, то мои слова обязательно перевесят
          их: …».
        </b>
      </>
    ),
  },
  {
    id: "f18",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(18)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводит его Абу Дауд (5084) от Абу Малика аль-Аш’ари, который сообщил,
        что Посланник Аллаха ﷺ сказал:{" "}
        <b>
          «Если встретит один из вас утро, то пусть скажет: «…». Затем, если
          встретит вечер, то пусть скажет то же самое».
        </b>{" "}
        <br />
        Хорошую степень этого хадиса подтвердил шейх аль-Альбани в «Сахих
        аль-Джами’» (352).
      </>
    ),
  },
  {
    id: "f19",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(19)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводит его Абу Дауд (5090) от ‘АбдурРахмана ибн Аби Бакраты, который
        сказал своему отцу: «О батюшка, я слышу, как ты каждое утро произносишь
        в мольбе: «...». Ты повторяешь их трижды с наступлением утра и трижды с
        наступлением вечера? Он ответил: «Я слышал, как Посланник Аллаха ﷺ,
        произносил в мольбе эти слова, а я предпочитаю (люблю) следовать его
        пути». Сказал шейх аль-Альбани в «Сахих сунан Аби Дауд» (3/251): «Иснад
        этого хадиса хороший».
      </>
    ),
  },
  {
    id: "f20",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(20)</span> - От переводчика
      </>
    ),
    Text: () => (
      <>
        <b>Ханиф</b> – это единобожник, следующий истине, оставивший многобожие,
        и устремившийся в своих поклонениях к Одному лишь Аллаху.
      </>
    ),
  },
  {
    id: "f21",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(21)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его ан-Насаи в «’Амалю аль-йауми уа аль-лейля» (343), имам
        Ахмад в «аль-Муснад» (3/407), ад-Дарими в «ас-Сунан» (2/2688), от
        ‘АбдурРахмана ибн Абза, который сказал:{" "}
        <b>«Встретив утро Посланник Аллаха ﷺ говорил: «...»».</b>
      </>
    ),
  },
  {
    id: "f22",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(22)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его Ибн Маджа (925), Ибн ас-Сунний в «’Амаль аль-йауми уа
        аль-лейля» (53) от Умму Салямы (да будет доволен ею Аллах), которая
        сообщила, что:{" "}
        <b>
          «Посланник Аллаха ﷺ, завершая утреннюю молитву, после произнесения
          слов таслима («ас-саляму ‘алейкум»), говорил: «...»».
        </b>
      </>
    ),
  },
  {
    id: "f23",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(23)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводят его Абу Дауд (5081), Ибн ас-Сунний в «’Амалю аль-йауми уа
        аль-лейля» (70) из хадиса Абу Дарды, в котором передаётся, что Пророк ﷺ
        сказал:{" "}
        <b>
          {" "}
          «Того, кто каждый день, встретив утро и встретив вечер, станет
          произносить по семь раз слова «...», Всевышний Аллах избавит от забот
          этого мира и мира Вечного».
        </b>{" "}
        <br />
        Сказал шейх аль-Арнаут в исследовании (хадисов) книги «Заад аль-Ма’ад»
        (2/376): «Передатчики хадиса, приводимого Абу Даудом, являются
        заслуживающими доверия, однако в этом хадисе есть отвергаемая добавка -
        это слова «…правдиво он произнесёт это или лживо».
      </>
    ),
  },
  {
    id: "f24",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(24)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его аль-‘Укъайлий в «ад-Ду’афа» (стр. 411), Абу Ну’айм в
        «Ахбару Асбахан» (1/60) по версии ат-Табарани с достоверным иснадом от
        Абу Бурдаты ибн аби Мусы, (а тот) от своего отца, который сказал:{" "}
        <b>
          «Однажды, когда как мы находились (сидели) в собрании, пришёл
          Посланник Аллаха ﷺ и сказал: «Не было такого случая, чтобы я встретил
          утро, не попросив у Аллаха прощения сто раз»».
        </b>{" "}
        <br />
        Достоверность хадиса подтвердил шейх аль-Альбани в «ас-Сильсиляту
        ас-Сахиха» (1600), а также в «Сахих аль-Джами’» (5410).
      </>
    ),
  },
  {
    id: "f25",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(25)</span> - Хадис хороший
      </>
    ),
    Text: () => (
      <>
        Приводит его ан-Насаи в «’Амалю аль-йауми уа аль-лейля» (476/821) от
        ‘Амра ибн Шу’айба, от его отца, от его деда, от Посланника Аллаха ﷺ,
        который сказал:{" "}
        <b>
          «Для того, кто произнесёт до восхода и до заката солнца сто раз:
          «Пречист Аллах (от всех недостатков)», это будет лучше, чем владение
          ста верблюдами. Для того, кто скажет до восхода и до заката солнца сто
          раз: «Хвала Аллаху», это будет лучше, чем сто лошадей, уготованных для
          сражения на пути Аллаха. Для того, кто произнесёт до восхода и до
          заката солнца сто раз: «Аллах Велик», это будет лучше, чем
          освобождение ста рабов. И никто не придёт в Судный День с деянием
          лучшим, чем тот, кто до восхода и до заката солнца произнесёт сто раз:
          «Нет божества, достойного поклонения, кроме Аллаха, Единственного, у
          Которого нет сотоварища. Только Ему принадлежит вся власть и вся
          хвала, и Он над каждой вещью мощен», - если не считать человека,
          который станет говорить то же самое или добавлять к этому количеству
          ещё больше».
        </b>
      </>
    ),
  },
  {
    id: "f26",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(26)</span> - Хадис достоверный
      </>
    ),
    Text: () => (
      <>
        Приводят его имам Муслим (2692), ат-Тирмизи (3469), ан-Насаи в «’Амалю
        аль-йауми уа аль-лейля» (380/хадис 568) от Абу Хурэйры, который сообщил,
        что Пророк ﷺ сказал:{" "}
        <b>
          {" "}
          «В Судный День никто не опередит того, кто, встретив утро и вечер,
          будет по сто раз повторять слова: «Пречист Аллах (от всех недостатков)
          и хвала Ему», - если не считать человека, который станет говорить то
          же самое или добавлять к этому количеству ещё больше».
        </b>{" "}
        Сказал Абу ‘Иса (ат-Тирмизи): «Этот хадис хороший (или) достоверный,
        пришёл путём передачи «аль-ахад» (гъариб)». У Абу Дауда (5091)
        передается хадис от Абу Хурэйры (да будет доволен им Аллах), в котором
        он сообщил, что Посланник Аллаха ﷺ сказал:{" "}
        <b>
          {" "}
          «Никто из творений не совершит (не достигнет) того, что совершит
          (достигнет) тот, кто, встретив утро и вечер, будет по сто раз
          говорить: «Пречист Великий Аллах и хвала Ему».
        </b>{" "}
        <br />
        Подобный хадис передается имамом аль-Бухари (6042) от Абу Хурэйры (да
        будет доволен им Аллах), в котором сообщается, что Посланник Аллаха ﷺ
        сказал:{" "}
        <b>
          «Тому, кто сто раз в день скажет: «Пречист Аллах (от всех недостатков)
          и хвала Ему», - будут стерты его прегрешения, даже если их число будет
          подобно (пузырькам) морской пены».
        </b>
      </>
    ),
  },
  {
    id: "f27",
    Title: () => (
      <>
        <span style={{ color: "#DF0000" }}>(27)</span> - Приводят хадис имам
        аль-Бухари (3190) и имам Муслим (406)
      </>
    ),
    Text: () => (
      <>
        Также приводит его ат-Табарани с хорошим иснадом от Абу Дарды (да будет
        доволен им Аллах), который сообщил, что Посланник Аллаха ﷺ сказал:{" "}
        <b>
          «Тот, кто, встретив утро и встретив вечер, по десять раз попросит
          Аллаха восхваления для меня, тому будет даровано моё заступничество в
          День Воскрешения».
        </b>
      </>
    ),
    last: true,
  },
];

export default morning_footnotes;
