import "../styles/MenuModal.scss";
import modalMenu_close from "../assets/modalMenu_close.svg";

import { useGlobalContext } from "../context";
import { Link } from "react-router-dom";

function MenuModal() {
  const { isDarkTheme, modalMenuOpen, setModalMenuOpen } = useGlobalContext();
  if (modalMenuOpen)
    return (
      <>
        <div
          className={`${
            isDarkTheme ? `modalMenu modalMenu-dark` : `modalMenu`
          }`}
        >
          <div className="modalMenuWrapper">
            <div className="modalMenu__list">
              <Link
                to={`/morning_azkaras`}
                onClick={() => setModalMenuOpen(false)}
                className="modalLink"
              >
                Утренние азкары
              </Link>
              <Link
                to={"/evening_azkaras"}
                onClick={() => setModalMenuOpen(false)}
                className="modalLink"
              >
                Вечерние азкары
              </Link>
              <Link
                onClick={() => setModalMenuOpen(false)}
                className="modalLink"
              >
                Имена Аллаха
              </Link>
              <Link
                onClick={() => setModalMenuOpen(false)}
                to={"/books"}
                className="modalLink"
              >
                Книги
              </Link>
              <Link
                onClick={() => setModalMenuOpen(false)}
                to={"/namaz"}
                className="modalLink"
              >
                Намаз
              </Link>
              <Link
                onClick={() => setModalMenuOpen(false)}
                to={"/ablution"}
                className="modalLink"
              >
                Омовение
              </Link>
              <Link
                onClick={() => setModalMenuOpen(false)}
                className="modalLink"
              >
                Донат
              </Link>
              <button
                className="modalMenu__close"
                onClick={() => setModalMenuOpen(false)}
              >
                <img src={modalMenu_close} alt="modal_close" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default MenuModal;
