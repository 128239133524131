import { useGlobalContext } from "../context";

import "../styles/IntroductionAzkaras.scss";

import arrow_left_light from "../assets/arrow_left_light.svg";
import arrow_left_dark from "../assets/arrow_left_dark.svg";
import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import NavBar from "./Navbar";
import Footer from "./Footer";
import MenuModal from "./MenuModal";
import { Link } from "react-router-dom";

function IntroductionAblutionNamaz() {
  const { isDarkTheme, introductionAblutionNamazBack } = useGlobalContext();

  return (
    <>
      <div
        className={`${isDarkTheme ? `navBarIntro-dark` : `navBarIntro-light`}`}
      >
        <NavBar />
      </div>
      <div
        className={`${
          isDarkTheme
            ? `content content-dark content-introduction`
            : `content content-introduction`
        }`}
      >
        <div className="contentWrapper contentWrapper-introduction">
          <div
            className={`${
              isDarkTheme
                ? `linkBackToAzkaras linkBackToAzkaras-dark`
                : `linkBackToAzkaras`
            }`}
          >
            <Link
              to={`/` + introductionAblutionNamazBack}
              className={`${
                isDarkTheme
                  ? `content__backBtn content__backBtn-dark`
                  : `content__backBtn`
              }`}
            >
              <img
                src={`${isDarkTheme ? arrow_left_dark : arrow_left_light}`}
                alt="arrow_left"
              />
            </Link>
            Вернуться
          </div>
          <div
            className={`${
              isDarkTheme ? `introduction introduction-dark` : `introduction`
            }`}
          >
            <div
              className={`${
                isDarkTheme
                  ? `introduction__title introduction__title-fix introduction__title-dark`
                  : `introduction__title introduction__title-fix`
              }`}
            >
              Введение
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <p>
                С именем Аллаха Милостивого, Милосердного, мир и благословение
                пророку Мухаммаду (мир ему и благословение Аллаха) и его семье,
                его сподвижникам и всем последовавшим по его праведному пути до
                Судного Дня.
              </p>
              <b>Молитва — один из столпов ислама</b>
              <p>
                Это первый вид поклонения, который Всевышний Аллах вменил в
                обязанность верующим. Произошло это во время вознесения Пророка
                (мир ему и благословение Аллаха) на небеса. Молитву обязан
                совершать каждый мусульманин, достигший совершеннолетия и
                находящийся в здравом уме.
              </p>
              <p>
                Молитва — первое деяние, о котором будет спрошен верующий в
                Судный день. Так, Посланник Аллаха (мир ему и благословение
                Аллаха) сказал: «Первым деянием, за которое раба Аллаха
                подвергнут расчёту в Судный день, будет молитва. Если он
                совершает её должным образом, то и остальные его деяния будут
                благими. Если же она была неудовлетворительной, то и остальные
                его деяния будут неудовлетворительными».
              </p>
              <p>
                Важность молитвы столь велика, что Всевышний Аллах повелел нам
                совершать её в любой ситуации, в здравии и в болезни, стоя, сидя
                или лёжа, в пути или дома, в безопасности и когда нам что-то
                угрожает. Молитва — наш ключ к успеху в этом мире и в мире
                вечном. Как сказал Всевышний Аллах в Своей Великой Книге:
                «Поистине, преуспели верующие, которые смиренны во время своих
                молитв» (23:1–2). А в других аятах сказано: «которые регулярно
                совершают молитву. Именно они являются наследниками, которые
                унаследуют Фирдаус, в котором они пребудут вечно» (23:9–11).
                Молитва очень легка и приносит огромную пользу сердцу верующего
                и его телу, отдельному человеку и целому обществу. Молитва
                связывает каждого из нас с Господом. Перед тем, как приступить к
                её совершению, мы очищаемся телесно и духовно. Мы стоим перед
                Господом, исполненные смирения и покорности, приближаясь к Нему
                посредством действий, которые Он предписал нам совершать во
                время молитвы: поминания Аллаха, чтения Корана, поясных и земных
                поклонов, сидения и стояния — и просим о чём-нибудь мирском или
                о благополучии в мире вечном.
              </p>
              <p>
                Молитва увеличивает веру, стирает грехи, ставит человека в один
                ряд с благочестивыми и праведными, помогает ему в религии и в
                мирских делах и оберегает от порицаемого и мерзкого. Молитва —
                одна из важнейших составляющих ислама, поэтому Всевышний
                облегчил её так, как не облегчал Он ни один вид поклонения.
                Аллах сделал её необходимые условия и столпы лёгкими, а число
                составляющих её действий — небольшим. Назначение данного
                приложения — помочь вам научиться правильно совершать молитву —
                так, как учил Пророк Мухаммад (мир ему и благословение Аллаха).
                Иными словами, перед вами упрощённое иллюстрированное пошаговое
                руководство, которое научит вас совершать малое омовение (вуду’)
                и молитву и послужит хорошим стимулом к их регулярному
                совершению. Некоторые слова и действия в молитве имеют вариации,
                закреплённые в Сунне, которую мы должны изучать. Однако учитывая
                обязательность молитвы и необходимость начать совершать её как
                можно скорее, в данном приложении мы предлагаем вашему вниманию
                один вариант совершения молитвы, изучив который вы сможете в
                самое ближайшее время начать исполнять эту крайне важную
                обязанность верующего перед Всевышним.
              </p>
              <p>
                Более подробное описание можно найти, например, в книге
                «Описание молитвы Пророка», которая доступна в исламских
                магазинах, в том числе в Интернете. Просим Аллаха принять наш
                скромный труд и простить нам наши упущения. Мы очень надеемся,
                что, с позволения Всевышнего, этот сайт окажется полезным для
                вас.
              </p>
              <p>
                Представленные материалы были позаимствованы из приложения "Моя
                молитва".
              </p>
            </div>
          </div>

          <div className="botLinksWrap">
            <div
              className={`${
                isDarkTheme
                  ? `linkBackToAzkaras linkBackToAzkaras-bottom linkBackToAzkaras-dark`
                  : `linkBackToAzkaras linkBackToAzkaras-bottom`
              }`}
            >
              <Link
                to={`/` + introductionAblutionNamazBack}
                className={`${
                  isDarkTheme
                    ? `content__backBtn content__backBtn-dark`
                    : `content__backBtn`
                }`}
              >
                <img
                  src={`${isDarkTheme ? arrow_left_dark : arrow_left_light}`}
                  alt="arrow_left"
                />
              </Link>
              Вернуться
            </div>
            <button
              className={`${
                isDarkTheme
                  ? `content__returnBtn content__returnBtn-dark`
                  : `content__returnBtn`
              }`}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }
            >
              <img
                src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
                alt="arrowTop"
              />
            </button>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default IntroductionAblutionNamaz;
