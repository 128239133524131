import React, { useState, useContext, useEffect } from "react";

const AppContext = React.createContext();
const AppProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(
    JSON.parse(window.localStorage.getItem("isDarkTheme")) || false
  );
  const [modalMorningOpen, setModalMorningOpen] = useState(false);
  const [modalMenuOpen, setModalMenuOpen] = useState(false);
  const [modalEvening, setModalEvening] = useState([]);
  const [modalMorning, setModalMorning] = useState([]);
  const [introductionAzkarasBack, setIntroductionAzkarasBack] =
    useState("/morning_azkaras");
  const [introductionAblutionNamazBack, setIntroductionAblutionNamazBack] =
    useState("namaz");

  useEffect(() => {
    setIsDarkTheme(JSON.parse(window.localStorage.getItem("isDarkTheme")));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("isDarkTheme", isDarkTheme);
  }, [isDarkTheme]);

  // Добавить сохранение в localStorage introductionBack после рефакторинга приложения

  return (
    <AppContext.Provider
      value={{
        isDarkTheme,
        setIsDarkTheme,
        modalMorning,
        setModalMorning,
        modalMorningOpen,
        modalMenuOpen,
        setModalMenuOpen,
        setModalMorningOpen,
        introductionAzkarasBack,
        setIntroductionAzkarasBack,
        introductionAblutionNamazBack,
        setIntroductionAblutionNamazBack,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};
export { AppContext, AppProvider };
