import book_1 from "../assets/books/book_1.png";
import book_2 from "../assets/books/book_2.png";
import book_3 from "../assets/books/book_3.png";
import book_4 from "../assets/books/book_4.png";
import book_5 from "../assets/books/book_5.png";
import book_6 from "../assets/books/book_6.png";
import book_7 from "../assets/books/book_7.png";
import book_8 from "../assets/books/book_8.png";
import book_9 from "../assets/books/book_9.png";
import book_10 from "../assets/books/book_10.png";
import book_11 from "../assets/books/book_11.png";
import book_12 from "../assets/books/book_12.png";
import book_13 from "../assets/books/book_13.png";
import book_14 from "../assets/books/book_14.png";
import book_15 from "../assets/books/book_15.png";
import book_16 from "../assets/books/book_16.png";
import book_17 from "../assets/books/book_17.png";
import book_18 from "../assets/books/book_18.png";
import book_19 from "../assets/books/book_19.png";
import book_20 from "../assets/books/book_20.png";

const books = [
  {
    id: "book1",
    title:
      "Разъяснение шариатских постановлений относительно естественных женских кровяных выделений",
    authors: "Авторы: Шейх Сулейман ибн СалимулЛах ар-Рухайли",
    img: book_1,
  },
  {
    id: "book2",
    title: "Основы саляфитского призыва",
    authors: "Авторы: Шейх Абдус-Салям ибн Барджис, да помилует его Аллах",
    img: book_2,
  },
  {
    id: "book3",
    title: "Искренность",
    authors: "Авторы: Шейх Мухаммад ибн Хади аль-Мадхали",
    img: book_3,
  },
  {
    id: "book4",
    title: "Семейные отношения",
    authors: "Авторы: Шейх Сулейман ар-Рухейли",
    img: book_4,
  },
  {
    id: "book5",
    title: "Разъяснение “Четырех правил”",
    authors: "Авторы: Шейх Абдур-Раззак ибн абдуль-Мухсин Аль-Бадр",
    img: book_5,
  },
  {
    id: "book6",
    title: "Минхадж для ребенка мусульманина",
    authors: "Авторы: Шейх Холид Усман",
    img: book_6,
  },
  {
    id: "book7",
    title:
      "Слова Единобожия “ля иляха илля-Ллах”, их достоинства, смысл, условия и то, что их аннулирует",
    authors: "Авторы: Шейх АбдурРаззак аль-Бадр",
    img: book_7,
  },
  {
    id: "book8",
    title: "Условия свидетельства “Мухаммад - посланник Аллаха”",
    authors: "Авторы: Подготовил Марат Абу Зарр",
    img: book_8,
  },
  {
    id: "book9",
    title:
      "Условия свидетельства “Нет истинного божества, кроме Аллаха”, и действия, которые выводят человека из Ислама",
    authors: "Авторы: Мухаммад Са'ид Раслян",
    img: book_9,
  },
  {
    id: "book10",
    title: (
      <>
        <div style={{ textTransform: "uppercase" }}>
          Правильное вероубеждение в вопросах и ответах
        </div>
      </>
    ),
    authors: "Авторы: Шейх 'АбдусСалям ибн Барджис",
    img: book_10,
  },
  {
    id: "book11",
    title: (
      <>
        <div style={{ textTransform: "uppercase" }}>
          УТРЕННИЕ И ВЕЧЕРНИЕ ПОМИНАНИЯ АЛЛАХА
        </div>
      </>
    ),
    authors: "Авторы: Абу 'АбдиЛлях Мухаммад ибн Са'ид Раслян",
    img: book_11,
  },
  {
    id: "book12",
    title: "Наша обязанность по отношению к тому, что приказал нам Аллах",
    authors: "Авторы: Шейх Абдур-Раззак ибн абдуль-Мухсин Аль-Бадр",
    img: book_12,
  },
  {
    id: "book13",
    title: `Послание “Четыре правила”`,
    authors: "Авторы: Разъяснение шейха Абдуль-'Азиза ибн АбдиЛлах ибн База",
    img: book_13,
  },
  {
    id: "book14",
    title: "Общие положения ИСЛАМСКОГО ВЕРОУБЕЖДЕНИЯ",
    authors: "Авторы: Шейх Мухаммад ибн Солих аль-Усаймин",
    img: book_14,
  },
  {
    id: "book15",
    title: "100 вопросов и ответы на них по теме акыды и таухида",
    authors:
      "Авторы: Составил: Абдуль-'Азиз ибн Мухаммад аш-Ша'лян. Вступление к этой книге сделал шейх Салих аль-Фаузан",
    img: book_15,
  },
  {
    id: "book16",
    title:
      "Влияние шариатских поминаний (азкаров) относительно избавления от тревоги и грусти",
    authors: "Авторы: Шейх Абдур-Раззак аль-Бадр",
    img: book_16,
  },
  {
    id: "book17",
    title: "Райхана",
    authors:
      "Авторы: Шейх Абу Абдиль-А' ля Холид ибн Мухаммад ибн Усман аль-Мисри",
    img: book_17,
  },
  {
    id: "book18",
    title: "Буклет - пост",
    authors: "Авторы: Подготовил: АбдуЛлах абу Марьям",
    img: book_18,
  },
  {
    id: "book19",
    title: "Истинное счастье",
    authors: "Авторы: Шейх Сулейман ар-Рухейли",
    img: book_19,
  },
  {
    id: "book20",
    title: "Месяц Рамадан",
    authors: "Авторы: Шейх абдуль-Мухсин бну Хамд аль-Аббад аль Бадр",
    img: book_20,
  },
];

export default books;
