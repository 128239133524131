import { useGlobalContext } from "../context";

import AudioPlayer from "../audio_player/AudioPlayer";
import SocialLinks from "./SocialLinks";

import "../styles/SingleAzkar.scss";

function SingleAzkar(props) {
  const { isDarkTheme } = useGlobalContext();
  const { Title, handleClick } = props;

  let counter;
  if (props.counter) {
    counter = <div className="azkar__counter">{props.counter}</div>;
  }

  let original;
  if (props.original) {
    original = (
      <>
        <div className="azkar__original">{props.original}</div>
        <div
          className={`${
            isDarkTheme
              ? `azkar__line azkar__line-bottom azkar__line-dark`
              : `azkar__line azkar__line-bottom`
          }`}
        ></div>
      </>
    );
  }

  let translate;
  if (props.translate) {
    translate = <div className="azkar__translate">{props.translate}</div>;
  }

  if (props.custom) {
    return (
      <div className={`${isDarkTheme ? `azkar azkar-dark` : `azkar`}`}>
        <div className="azkarTop">
          <div
            className={`${
              isDarkTheme ? `azkar__title azkar__title-dark` : `azkar__title`
            }`}
          >
            <Title handleClick={handleClick} />
          </div>
          {counter}
        </div>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
        {original}
        {translate}
        <div className="azkar__transcription">{props.transcription}</div>
        <div className="azkar__player">
          <AudioPlayer audioSrc={props.audio}></AudioPlayer>
        </div>
        <SocialLinks></SocialLinks>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
        <div className="azkar__original">{props.original1}</div>
        <div
          className={`${
            isDarkTheme
              ? `azkar__line azkar__line-bottom azkar__line-dark`
              : `azkar__line azkar__line-bottom`
          }`}
        ></div>
        <div className="azkar__translate">{props.translate1}</div>
        <div className="azkar__transcription">{props.transcription1}</div>
        <div className="azkar__player">
          <AudioPlayer audioSrc={props.audio1}></AudioPlayer>
        </div>
        <SocialLinks></SocialLinks>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
        <div className="azkar__original">{props.original2}</div>
        <div
          className={`${
            isDarkTheme
              ? `azkar__line azkar__line-bottom azkar__line-dark`
              : `azkar__line azkar__line-bottom`
          }`}
        ></div>
        <div className="azkar__translate">{props.translate2}</div>
        <div className="azkar__transcription">{props.transcription2}</div>
        <div className="azkar__player">
          <AudioPlayer audioSrc={props.audio2}></AudioPlayer>
        </div>
        <SocialLinks></SocialLinks>
        <div
          className={`${
            isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
          }`}
        ></div>
        <div className="azkar__original">{props.original3}</div>
        <div
          className={`${
            isDarkTheme
              ? `azkar__line azkar__line-bottom azkar__line-dark`
              : `azkar__line azkar__line-bottom`
          }`}
        ></div>
        <div className="azkar__translate">{props.translate3}</div>
        <div className="azkar__transcription">{props.transcription3}</div>
        <div className="azkar__player">
          <AudioPlayer audioSrc={props.audio3}></AudioPlayer>
        </div>
        <SocialLinks></SocialLinks>
      </div>
    );
  }

  return (
    <div className={`${isDarkTheme ? `azkar azkar-dark` : `azkar`}`}>
      <div className="azkarTop">
        <div
          className={`${
            isDarkTheme ? `azkar__title azkar__title-dark` : `azkar__title`
          }`}
        >
          <Title handleClick={handleClick} />
        </div>
        {counter}
      </div>
      <div
        className={`${
          isDarkTheme ? `azkar__line azkar__line-dark` : `azkar__line`
        }`}
      ></div>
      {original}
      {translate}
      <div className="azkar__transcription">{props.transcription}</div>
      <div className="azkar__player">
        <AudioPlayer audioSrc={props.audio}></AudioPlayer>
      </div>
      <SocialLinks></SocialLinks>
    </div>
  );
}

export default SingleAzkar;
