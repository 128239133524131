import namaz_c1_light from "../assets/namaz/namaz_c1_light.svg";
import namaz_c1_dark from "../assets/namaz/namaz_c1_dark.svg";
import namaz_c2_light from "../assets/namaz/namaz_c2_light.svg";
import namaz_c2_dark from "../assets/namaz/namaz_c2_dark.svg";
import namaz_c3_light from "../assets/namaz/namaz_c3_light.svg";
import namaz_c3_dark from "../assets/namaz/namaz_c3_dark.svg";

const namaz3 = [
  {
    id: "c1",
    img_dark: namaz_c1_dark,
    img_light: namaz_c1_light,
    img_className: "img_c1",
    linkId: "grace__end",
    mainTitle: <>Завершение молитвы</>,
    title: "Шаг №1",
    text: "Молящийся произносит саляват:",
    translate: (
      <>
        <div className="namaz__translateFix">
          <i>
            Аллахумма салли ‘аля Мухаммадин ва ‘аля али Мухаммадин кя-ма
            саллейта ‘аля Ибрахима ва ‘аля али Ибрахима инна-ка Хамидун (ред.)
            Маджидун Аллахумма барик ‘аля Мухаммадин ва ‘аля али Мухаммадин
            кя-ма баракта ‘аля Ибрахима ва ‘аля али Ибрахима инна-ка Хамидун
            Маджидун
          </i>
        </div>
      </>
    ),
  },
  {
    id: "c2",
    img_dark: namaz_c2_dark,
    img_light: namaz_c2_light,
    img_className: "img_c2",
    title: "Шаг №2",
    text: "Он поворачивает голову направо и говорит:",
    original: "ﺍﻟﺴَّﻼﻡُ ﻋَﻠَﻴْﻜُﻢ ﻭَﺭَﺣْﻤَﺔُ ﺍﻟﻠﻪِ",
    translate: (
      <>
        Ас-саляму ‘алей-кум ва рахмату Ллах <br />
        Мир вам и милость Аллаха.
      </>
    ),
  },
  {
    id: "c3",
    img_dark: namaz_c3_dark,
    img_light: namaz_c3_light,
    img_className: "img_c3",
    title: "Шаг №3",
    text: "Он поворачивает голову налево и говорит:",
    original: "ﺍﻟﺴَّﻼﻡُ ﻋَﻠَﻴْﻜُﻢ ﻭَﺭَﺣْﻤَﺔُ ﺍﻟﻠﻪِ",
    translate: (
      <>
        Ас-саляму ‘алей-кум ва рахмату Ллах <br />
        Мир вам и милость Аллаха.
      </>
    ),
    ending: "На этом молитва завершена.",
  },
];

export default namaz3;
