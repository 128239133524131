import { useGlobalContext } from "../context";

import "../styles/IntroductionAzkaras.scss";

import arrow_left_light from "../assets/arrow_left_light.svg";
import arrow_left_dark from "../assets/arrow_left_dark.svg";
import arrowTop from "../assets/arrowTop.svg";
import arrowTopDark from "../assets/arrowTopDark.svg";

import NavBar from "./Navbar";
import Footer from "./Footer";
import MenuModal from "./MenuModal";
import { Link } from "react-router-dom";

function IntroductionAzkaras() {
  const { isDarkTheme, introductionAzkarasBack } = useGlobalContext();

  return (
    <>
      <div
        className={`${isDarkTheme ? `navBarIntro-dark` : `navBarIntro-light`}`}
      >
        <NavBar />
      </div>
      <div
        className={`${
          isDarkTheme
            ? `content content-dark content-introduction`
            : `content content-introduction`
        }`}
      >
        <div className="contentWrapper contentWrapper-introduction">
          <div
            className={`${
              isDarkTheme
                ? `linkBackToAzkaras linkBackToAzkaras-dark`
                : `linkBackToAzkaras`
            }`}
          >
            <Link
              to={`/` + introductionAzkarasBack}
              className={`${
                isDarkTheme
                  ? `content__backBtn content__backBtn-dark`
                  : `content__backBtn`
              }`}
            >
              <img
                src={`${isDarkTheme ? arrow_left_dark : arrow_left_light}`}
                alt="arrow_left"
              />
            </Link>
            Вернуться
          </div>
          <div
            className={`${
              isDarkTheme ? `introduction introduction-dark` : `introduction`
            }`}
          >
            <div className="introduction__original introduction__original-top">
              بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ
            </div>
            <div
              className={`${
                isDarkTheme
                  ? `introduction__title introduction__title-dark`
                  : `introduction__title`
              }`}
            >
              Утренние и вечерние поминания Аллаха
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              Собрал достопочтенный шейх <br />
              Мухаммад ибн Са’ид Раслян <br />
              да хранит его Аллах <br />
              Перевёл Марат абу Зарр
            </div>
            <div className="introduction__line"></div>
            <div
              className={`${
                isDarkTheme
                  ? `introduction__title introduction__title-dark`
                  : `introduction__title`
              }`}
            >
              Вступление переводчика
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              Хвала Аллаху - Господу Миров. Его восхваления и наши приветствия
              пророку Мухаммаду ﷺ. А затем: «Утренние и вечерние поминания
              Аллаха», которые собрал наш достопочтенный шейх Мухаммад Са’ид
              Раслян, да хранит его Аллах, являются одним из видов поклонений
              Всевышнему Аллаху. А поклонение Ему – это то, для чего мы были
              сотворены, о чём Всевышний сообщил в Коране, сказав:
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              وَمَا خَلَقْتُ الْجِنَّ وَالْإِنْسَ إِلَّا لِيَعْبُدُونِ
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <p>
                <b>
                  «Я не сотворил ни джиннов, ни людей (ни для чего), кроме как
                  для того, чтобы они поклонялись Мне»
                </b>{" "}
                (сура «аз-Зарийат», 56).
              </p>
              <p>
                <b>«Поклонялись»</b> - значит «уединяли» Аллаха в своих
                поклонениях, которые Он любит и которыми Он доволен, и которые
                состоят из слов и дел, скрытых и явных, на которые есть указания
                в Коране и Сунне Его Посланника ﷺ. Ведь Ислам - это религия
                единобожия, это выполнение того, что любит Аллах и отдаление от
                всего того, что Он запретил и чем Он недоволен. Вместе с этим,
                каждый должен знать, что самый великий грех перед Аллахом – это
                многобожие, придание Аллаху сотоварищей или равных в поклонении,
                в Его божественных качествах и делах. Каждый мусульманин должен
                помнить и сторониться этого, ибо Аллах не простит грех
                многобожия тому, кто встретит Его с ним в Судный День. Всевышний
                Аллах говорит:
              </p>
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              إِنَّ اللَّهَ لَا يَغْفِرُ أَنْ يُشْرَكَ بِهِ
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <p>
                <b>
                  «Воистину, Аллах не прощает, когда к Нему приобщают
                  сотоварищей»
                </b>{" "}
                (сура «ан-Ниса», 48).
              </p>
              <p>
                <b>«Утренние и вечерние поминания»</b> являются примером того,
                как приближался к Аллаху наш пророк Мухаммад ﷺ. А самый
                правильный путь к Аллаху – это путь пророка Мухаммада ﷺ, который
                совершенным образом донёс религию Творца, и наилучшим образом
                выполнил Его приказ, в котором сказано:
              </p>
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              وَاذْكُرْ رَبَّكَ فِي نَفْسِكَ تَضَرُّعًا وَخِيفَةً وَدُونَ
              الْجَهْرِ مِنَ الْقَوْلِ بِالْغُدُوِّ وَالْآصَالِ
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <p>
                <b>
                  «Поминай Аллаха с покорностью и страхом, про себя и не громко,
                  по утрам и перед закатом»
                </b>{" "}
                (сура «аль-А’раф», 205). И да восхвалит Аллах нашего пророка
                Мухаммада, его семью и его сподвижников, и избавит их от всех
                бед.
              </p>
              <p>
                Марат Абу Зарр <br />
                Субки аль-Ахад, 25-го Джумада аль-Ахъира, 1435 г.х. <br />
                25 апреля 2014 г.
              </p>
            </div>
            <div className="introduction__line"></div>
            <div
              className={`${
                isDarkTheme
                  ? `introduction__title introduction__title-dark`
                  : `introduction__title`
              }`}
            >
              Вступление
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              Поистине, вся хвала принадлежит Аллаху, Его мы восхваляем, к Нему
              взываем о помощи, Его просим о прощении, к Нему мы прибегаем от
              зла наших душ и дурных наших дел. Того, кого Аллах наставит на
              прямой путь, никто не введёт в заблуждение, а того, кого Аллах
              введет в заблуждение, никто не наставит на прямой путь. И я
              свидетельствую, что нет истинного божества, кроме Аллаха, у
              Которого нет сотоварища, и свидетельствую, что Мухаммад - Его раб
              и посланник ﷺ.
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              يَا أَيُّهَا الَّذِينَ آمَنُوا اتَّقُوا اللَّهَ حَقَّ تُقَاتِهِ
              وَلَا تَمُوتُنَّ إِلَّا وَأَنْتُمْ مُسْلِمُونَ
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <b>
                «О те, которые уверовали! Бойтесь Аллаха должным образом и ни в
                коем случае не умирайте иначе, как будучи мусульманами!»
              </b>{" "}
              (сура «Али ‘Имран», 102).
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              يَا أَيُّهَا النَّاسُ اتَّقُوا رَبَّكُمُ الَّذِي خَلَقَكُمْ مِنْ
              نَفْسٍ وَاحِدَةٍ وَخَلَقَ مِنْهَا زَوْجَهَا وَبَثَّ مِنْهُمَا
              رِجَالًا كَثِيرًا وَنِسَاءً ۚ وَاتَّقُوا اللَّهَ الَّذِي
              تَسَاءَلُونَ بِهِ وَالْأَرْحَامَ ۚ إِنَّ اللَّهَ كَانَ عَلَيْكُمْ
              رَقِيبًا
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <b>
                «О люди! Бойтесь вашего Господа, Который сотворил вас из одного
                человека, сотворил из него пару ему и вывел из их потомства
                много мужчин и женщин, которых расселил на земле. Бойтесь
                Аллаха, именем Которого вы упрашиваете друг друга, и бойтесь
                разрывать родственные связи. Воистину, Аллах наблюдает за вами»
              </b>{" "}
              (сура «ан-Ниса», 1).
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              يَا أَيُّهَا الَّذِينَ آمَنُوا اتَّقُوا اللَّهَ وَقُولُوا قَوْلًا
              سَدِيدًا يُصْلِحْ لَكُمْ أَعْمَالَكُمْ وَيَغْفِرْ لَكُمْ
              ذُنُوبَكُمْ ۗ وَمَنْ يُطِعِ اللَّهَ وَرَسُولَهُ فَقَدْ فَازَ
              فَوْزًا عَظِيمًا
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <b>
                «О те, которые уверовали! Бойтесь Аллаха и говорите слово прямое
                (истинное). Тогда Он исправит ваши дела и простит вам ваши
                грехи. А кто повинуется Аллаху и Его Посланнику, тот достиг
                великого успеха»
              </b>{" "}
              (сура «аль-Ахзаб», 70-71). <br /> А затем: <br /> Поистине, самые
              правдивые слова – это Слова Аллаха, а самый истинный путь – это
              путь Мухаммада ﷺ. Самые скверные дела – это дела новоизобретённые,
              а каждое новоизобретённое (в религии) дело есть нововведение, а
              каждое нововведение - заблуждение, а любое заблуждение будет в
              Огне. А затем: Сказал Всевышний Аллах:
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__original">
              فَاذْكُرُونِي أَذْكُرْكُمْ
            </div>
            <div className="introduction__line"></div>
            <div className="introduction__text">
              <p>
                <b>
                  «Поминайте Меня, и Я буду поминать вас (в наивысшем
                  сообществе)»
                </b>{" "}
                (сура «аль-Бакъара», 152).
              </p>
              <p>
                Воистину, поминание (Аллаха) является причиной того, что
                поминающего поминает Сам Всевышний Аллах. И если бы не было у
                поминания (Аллаха) других достоинств и почёта, кроме этого, то и
                его одного было бы достаточно. А что можно сказать, если
                поминание Аллаха: порождает любовь (к Нему), которая является
                духом Ислама, стержнем религии, основой счастья и спасения. Оно
                (поминание Аллаха) оживляет сердце и внушает (в него) смиренный
                страх перед Господом. Оно открывает великие двери к познанию, и
                оно порождает (в человеке) раскаяние (перед Аллахом), стремление
                держать с Ним постоянную связь. Оно стирает прегрешения и
                устраняет отчуждённость между рабом и его Господом, Благословен
                Он и Возвышен. Оно (поминание Аллаха) является саженцем Рая … а
                есть также и множество других польз поминания Аллаха, о которых
                упомянул великий учёный Ибн аль-Къайим, да будет милостив к нему
                Аллах, в своей книге «аль-Уабиль ас-Сайиб» («Благодатный
                дождь»). <br /> И мне, с помощью Аллаха, Его силы и мощи,
                довелось работать над этой книгой. Я упомянул источники хадисов,
                приведённые имамом (Ибн аль-Къайимом) в конце книги, касающиеся
                утренних и вечерних поминаний Аллаха, указав в общем виде на их
                степень достоверности. Также в процессе работы над книгой и её
                толкованием, я привёл те достоверные хадисы, которые не привёл
                имам (Ибн аль-Къайим) да будет милостив к нему Аллах. И я
                попросил у Всевышнего Аллаха помощи в издании и распространении
                «Утренних и вечерних поминаний (Аллаха)» в отдельности, исходя
                из огромной нужды (мусульман) в них. Тогда мой сын Абу Мухаммад
                ‘АбдуЛлах ибн Мухаммад ибн Са’ид, да сопутствует ему Аллах,
                пожелал без излишних подробностей указать источники приводимых в
                моей работе хадисов, также, по возможности, указать на степень
                их достоверности, используя при этом слова учёных-мухаддисов
                (знатоков хадиса). Я разрешил ему это, после чего он занялся
                данной работой, стремясь, по возможности, сделать её краткой. И
                я прошу Аллаха, чтобы Он наделил нас искренностью в наших
                стремлениях и намерениях, чтобы Он сделал праведными (благими)
                наши слова и дела, и чтобы Он принял (их) от всех нас! И да
                восхвалит Всевышний Аллах нашего пророка Мухаммада, его семью и
                его сподвижников, и избавит их от всяких бед.
              </p>
              <p>
                Написал: <br />
                Абу ‘АбдиЛлях Мухаммад ибн Са’ид (Раслян) <br />
                Субки аль-Ахад, в день Джум’а (Пт) <br />
                16 рамадана 1428 г.х. <br />
                28 сентября 2007 г.
              </p>
            </div>
          </div>

          <div className="botLinksWrap">
            <div
              className={`${
                isDarkTheme
                  ? `linkBackToAzkaras linkBackToAzkaras-bottom linkBackToAzkaras-dark`
                  : `linkBackToAzkaras linkBackToAzkaras-bottom`
              }`}
            >
              <Link
                to={`/` + introductionAzkarasBack}
                className={`${
                  isDarkTheme
                    ? `content__backBtn content__backBtn-dark`
                    : `content__backBtn`
                }`}
              >
                <img
                  src={`${isDarkTheme ? arrow_left_dark : arrow_left_light}`}
                  alt="arrow_left"
                />
              </Link>
              Вернуться
            </div>
            <button
              className={`${
                isDarkTheme
                  ? `content__returnBtn content__returnBtn-dark`
                  : `content__returnBtn`
              }`}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }
            >
              <img
                src={`${isDarkTheme ? arrowTopDark : arrowTop}`}
                alt="arrowTop"
              />
            </button>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <MenuModal></MenuModal>
    </>
  );
}

export default IntroductionAzkaras;
